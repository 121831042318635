import React, {Component} from 'react';

import { BarChart, Cell, Bar as RCBar, YAxis, Tooltip } from 'recharts';

class Multibar extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        // before we draw anything let's preprocess this

        const agg = this.props.agg;
        const buckets = this.props.agg.buckets;

        let rowkeys = [];
        let colkeys = [];
        let lookup = {};
        let max = 0;

        buckets.forEach(function (bucket, index) {

            lookup[bucket['key']] = bucket;

            if (bucket['doc_count'] > max) { max = bucket['doc_count']; }

            const sp = bucket['key'].split('&');
            const rowkey = sp[0];
            const colkey = sp[1];

            //if (runkeys.includes(runkey) === false) { runkeys.push(runkey); }
            if (rowkeys.includes(rowkey) === false) { rowkeys.push(rowkey); }
            if (colkeys.includes(colkey) === false) { colkeys.push(colkey); }

        });

        const key = colkeys.map((colkey, index) => { 

            return (

                        <div className={ agg.keyitemclass || 'col-12' } key={ colkey } >

                            <span
                            className="mr-2"
                            style={ { backgroundColor: this.props.selectColor(colkey, colkey, index), width: '1em', height: '1em', display: 'inline-block' } }
                            />

                            <span
                            className="btn-link text-break"
                            onClick={
                            () => {
                            this.props.addagg(
                                    agg.aggfield || 'filter_aggs',
                                    [
                                    { 'filter_set_key': agg.colfskey, 'filter_key': colkey, 'filter_label': agg.coltitle + ' : ' + colkey },
                                    ]
                                    );

                            }
                            }
                            >
                                { colkey }
                            </span>

                        </div>

                    )


        });

        const rows = rowkeys.map((rowkey, rowidx) => { 

            const cols = colkeys.map((colkey, index) => { 

                const bucket = lookup[rowkey + '&' + colkey];
                const count = bucket['doc_count'];

                //return { 'name': item['key'], 'value': item['doc_count'], 'chartlabel': item['chartlabel'], 'formlabel': item['formlabel'], 'listlabel': item['listlabel'], 'url': item['url'] }
                return { 'name': colkey, 'value': count };

            });

            return (

                <div key={ rowidx } className={ this.props.agg.chartitemclass || 'col-12 col-md-6' }>
                    <div className={ this.props.agg.chartitemtitleclass || 'font-weight-bold' }>{ rowkey }</div>
                    <BarChart width={ this.props.agg['chartwidth'] || 120 } height={ this.props.agg['chartheight'] || 120 } data={ cols }>
                        { this.props.agg['ymax'] && (
                                <YAxis type="number" domain={[0, 200]} hide={true} />
                        ) }
                        <RCBar
                        dataKey="value"
                        isAnimationActive={true}
                        animationDuration={2000}
                        data={cols}	
                        >
                        { cols.map((entry, index) => 
                            <Cell
                            key={ index }
                            fill={ this.props.selectColor(entry.name, entry.listlabel, index) }
                            onClick={ (e) => {
                                if (entry.url) {
                                    this.redirect(entry.url)
                                } else {
                                    //this.props.addagg(this.props.agg.aggfield, entry.name, entry.formlabel)
                                    this.props.addagg(
                                        this.props.agg.aggfield || 'filter_aggs',
                                            [
                                                { 'filter_set_key': this.props.agg.rowfskey, 'filter_key': rowkey, 'filter_label': this.props.agg.rowtitle + ': ' + rowkey },
                                                { 'filter_set_key': this.props.agg.colfskey, 'filter_key': entry.name, 'filter_label': this.props.agg.coltitle + ': ' + entry.name },
                                            ]
                                        );
                                    }
                                    }}
                            cursor={'pointer'}
                            />
                            )}
                        </RCBar>
                        <Tooltip labelFormatter={ (idx) => { return cols[idx]['name']; } } />
                    </BarChart>
                </div>

        )});
 
        return (

            <div className={ agg.aggclass || 'row' }>

                <div className={ this.props.agg.chartclass || 'col-12 col-md-6' }>

                        <div className={ this.props.agg.chartitemsclass || 'row' }>

                            { rows }

                        </div>

                </div>
                <div className={ this.props.agg.keyclass || 'col-12 col-md-6' }>
      
                    { key } 

                </div>

            </div>

        )

    }
}

export default Multibar;
