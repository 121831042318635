import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Base from './components/Base';

function App() {
	return (
		<Router>
			<Base />
		</Router>	
	);
}

export default App;
