import React, {Component} from 'react';

import { Link } from 'react-router-dom';

const querystring = require('query-string');

class Hit extends Component {

	constructor(props) {
		super(props);
		this.state = {
		};

	}

	render() {

        const qs = querystring.parse(window.location.search);

        if (qs['fthil']) {

            const body = this.props.hit['body'].replaceAll(/[^a-zA-Z]/g, " ");
            var fthil = qs['fthil'].replaceAll(/[^a-zA-Z]/g, " ").trim();
            
            var idx = -1;

            // we might not get a hit with the whole phrase because of html tags breaking it up
            // So let's trim words off the end and keep trying

            while (idx === -1 && fthil.length > 10) {
 
                idx = body.indexOf(fthil);
                fthil = fthil.substr(0, fthil.lastIndexOf(' ') + 1).trim();

            }

            // The html break might be at ths start so let's try trimming words off the start and keep trying

            fthil = qs['fthil'].replaceAll(/[^a-zA-Z]/g, " ").trim();

            while (idx === -1 && fthil.length > 10) {
 
                idx = body.indexOf(fthil);
                fthil = fthil.substr(fthil.indexOf(' ') + 1).trim();

            }

            if (idx !== -1) {

                this.props.hit['body'] = this.props.hit['body'].substring(0, idx) + '<a id="fthil"></a>' + this.props.hit['body'].substring(idx);

            }

        }

		return (
			<div className={this.props.hit['class']}>

				{this.props.hit['label'] && (

					<React.Fragment>

						{this.props.hit['url'] && (

							<Link to={this.props.hit['url']} dangerouslySetInnerHTML={{__html: this.props.hit['label']}} />

						)}

						{!this.props.hit['url'] && (

							<span dangerouslySetInnerHTML={{__html: this.props.hit['label']}} />

						)}

					</React.Fragment>

				)}

				{this.props.hit['body'] && (

					<span dangerouslySetInnerHTML={{__html: this.props.hit['body']}} />

				)}
	
			</div>
		)

	}
}

export default Hit;
