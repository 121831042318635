import React, {Component} from 'react';

import Hit from './Hit';
import Loading from './Loading';

const querystring = require('query-string');

class Hitlist extends Component {

	constructor(props) {
		super(props);
		this.state = {
		};

	}

	componentDidMount() {

		if (window.location.hash) {

			var hash = window.location.hash.substring(1);
			var elem = document.getElementById(hash);
			if (elem) {
				elem.scrollIntoView();
			}

		} else {

            const qs = querystring.parse(window.location.search);

            if (qs['fthil']) {

                elem = document.getElementById('fthil');
                if (elem) {
                    elem.scrollIntoView();
                }


            }

        }

        if (this.props.infinite) {

            window.addEventListener('scroll', this.handleScroll);

        }

	}

    componentWillUnmount() {

        if (this.props.infinite) {

            window.removeEventListener('scroll', this.handleScroll);

        }

	}

    isInViewport(element) {

        var rect = element.getBoundingClientRect();
        var html = document.documentElement;

        return (
            //rect.top >= 0 &&
            //rect.left >= 0 &&
            (rect.bottom - 500) <= (window.innerHeight || html.clientHeight) //&& We only need to measure the bottom and we want it to load 500px before it is even on screen
            //rect.right <= (window.innerWidth || html.clientWidth)
        );

    }

    handleScroll = () => {

        if (!this.props.isLoadingExpand) {

            const ib = document.getElementById("infinite-marker");

            if (ib && this.isInViewport(ib)) {

                this.props.expandpage(this.props.from + this.props.pagesize)

            }

        }

    };
	
	render() {

		let hitlist = '';

		if (this.props.results.hits != null) {
			hitlist = this.props.results.hits.map((hit, index) => { 
				return <Hit
					key={index}
					index={index + 1}
					hit={hit}
					/>
			});
		}

		return (

			<React.Fragment>

				{ hitlist }

                { this.props.pagenav !== false && (

                   <React.Fragment>

                        { (this.props.from + this.props.pagesize) < this.props.results.total && this.props.infinite && (

                            <span id="infinite-marker">&#160;</span>

                        )}

                        { this.props.isLoadingExpand && (

                            <div className="col-12">
                                <Loading />
                            </div>

                        )} 

                        <div className="text-right">

                            { this.props.from > 0 && !this.props.infinite && (
                                
                                <React.Fragment>

                                    <button
                                    type="button"
                                    className="btn btn-link"
                                    onClick={() => this.props.switchpage(this.props.from - this.props.pagesize)}
                                    >
                                    { this.props.pagenav_prev || '<< Previous results' }
                                    </button>

                                    <span>Showing results <strong>{ this.props.from }</strong> to <strong>{ Math.min(this.props.from + this.props.pagesize, this.props.results.total) }</strong></span>

                                </React.Fragment>

                            )}

                            { (this.props.from + this.props.pagesize) < this.props.results.total && !this.props.infinite && (

                                    <button
                                    type="button"
                                    className="btn btn-link"
                                    onClick={() => this.props.switchpage(this.props.from + this.props.pagesize)}
                                    >
                                    { this.props.pagenav_next || 'More results >>' }
                                    </button>

                            )}

                        </div>

                    </React.Fragment>

                )}

			</React.Fragment>

		);

	}

}

export default Hitlist;
