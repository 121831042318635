import React, {Component} from 'react';

import { Link } from 'react-router-dom';

class Key extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };

    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render() {

        let agg = this.props.agg;

        let list = this.props.agg.buckets.map( (item, idx) => {

                let itemclasses = 'text-break ';

                const aggactive = this.props.aggactive(agg.fskey, item['key']);

                if (!item['url'] && item['url'] !== '' && item['doc_count'] > 0 && aggactive === false) { itemclasses = itemclasses + ' btn-link'; }

                if (item['doc_count'] === 0 || aggactive === true) { itemclasses = itemclasses + ' text-muted'; }

                let itembody = item['key'] + ' (' + this.numberWithCommas(item['doc_count']) + ')';

                if (item['body']) { itembody = item['body']; }

                return (

                        <div className={ agg.keyitemclass || 'col-12' } key={ item['key'] } >

                            <span
                            className="mr-2"
                            style={ { backgroundColor: this.props.selectColor(item['key'], item['listlabel'], idx), width: '1em', height: '1em', display: 'inline-block' } }
                            />

                            <span
                            className={ itemclasses }
                            onClick={
                                () => {

                                    if (!item['url'] && item['url'] !== '' && item['doc_count'] > 0 && aggactive === false) {

                                        this.props.addagg(
                                                agg.aggfield || 'filter_aggs',
                                                [
                                                { 'filter_set_key': agg.fskey, 'filter_key': item['key'], 'filter_label': agg.fstitle + ' : ' + item['key'] },
                                                ]
                                        );

                                    }

                                }
                            }
                            >

                                <React.Fragment>

                                    { item['url'] && item['url'] !== '' && (

                                        <Link to={ item['url'] } dangerouslySetInnerHTML={ { __html: itembody } } />

                                    )}

                                    { (!item['url'] || item['url'] === '') && (

                                        <span dangerouslySetInnerHTML={ { __html: itembody } } />

                                    )}

                                </React.Fragment>

                            </span>

                        </div>

                    )

        });

        return (

                <React.Fragment>

                    <div className={ agg.keyitemsclass || 'row' } >
                        { list }
                    </div>

                </React.Fragment>

               )

    }
}

export default Key;
