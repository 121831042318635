import React, {Component} from 'react';

import Pie from './Pie';
import Bar from './Bar';
import Key from './Key';
import Heatmap from './Heatmap';
import Multipie from './Multipie';
import Multibar from './Multibar';
import Multiheatmap from './Multiheatmap';
import Sankey from './Sankey';

class Agg extends Component {

	constructor(props) {
		super(props);
		this.state = {
		};

	}

    DEFAULT_COLORS = [ '#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5' ];

    selectColor = (key, label, idx) => {

		if (this.props.agg.colours) {

			return this.props.agg.colours[idx % this.props.agg.colours.length];

		}

		return this.DEFAULT_COLORS[idx % this.DEFAULT_COLORS.length];

    }
	
	render() {
	 
        //console.log('aggactive Agg');
        //console.log(this.props.aggactive);

		return (

			<React.Fragment>

                { this.props.agg.chart === 'pie' && (

    				<Pie
                    agg={ this.props.agg }
                    addagg={ this.props.addagg }
                    aggactive={this.props.aggactive}
                    selectColor={ this.selectColor }
                    />

				)}

                { this.props.agg.chart === 'bar' && (

    				<Bar
                    agg={ this.props.agg }
                    addagg={ this.props.addagg }
                    aggactive={this.props.aggactive}
                    selectColor={ this.selectColor }
                    />

				)}

                { this.props.agg.chart === 'key' && (

    				<Key
                    agg={ this.props.agg }
                    addagg={ this.props.addagg }
                    aggactive={this.props.aggactive}
                    selectColor={ this.selectColor }
                    />

				)}

                { this.props.agg.chart === 'heatmap' && (

    				<Heatmap
                    agg={ this.props.agg }
                    addagg={ this.props.addagg }
                    aggactive={this.props.aggactive}
                    />

				)}

                { this.props.agg.chart === 'multipie' && (

					<Multipie
                    agg={ this.props.agg }
                    addagg={ this.props.addagg }
                    aggactive={this.props.aggactive}
                    selectColor={ this.selectColor }
                    />

				)}

                { this.props.agg.chart === 'multibar' && (

					<Multibar
                    agg={ this.props.agg }
                    addagg={ this.props.addagg }
                    aggactive={this.props.aggactive}
                    selectColor={ this.selectColor }
                    />

				)}

                { this.props.agg.chart === 'multiheatmap' && (

					<Multiheatmap
                    agg={ this.props.agg }
                    addagg={ this.props.addagg }
                    aggactive={this.props.aggactive}
                    />

				)} 

                { this.props.agg.chart === 'sankey' && (

					<Sankey agg={ this.props.agg }
                    addagg={ this.props.addagg }
                    aggactive={this.props.aggactive}
                    />

				)} 

			</React.Fragment>
		)

	}
}

export default Agg;
