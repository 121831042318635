import React, {Component} from 'react';

class Error extends Component {

	constructor(props) {
		super(props);
		this.state = {
		}
	}

	render() {

		return (
    
            <React.Fragment>

			    <div className="d-flex justify-content-center mt-4 pt-4 display-4">
				    <i className="fa fa-bug fa-spin"></i> 
			    </div>
                <div className="d-flex justify-content-center mt-4 pt-4 mb-4 pb-4">
                    { this.props.code }
                </div>

            </React.Fragment>

		)

	}
}

export default Error;
