import React, {Component} from 'react';
//import { Link, Redirect } from 'react-router-dom';
import { PieChart, Pie as RCPie, Cell, Tooltip } from 'recharts';
//import { BarChart, Bar, YAxis } from 'recharts';
//import { Sankey } from 'recharts';
import Key from './Key';

//import Heatmap from './Heatmap';
//import Multiheatmap from './Multiheatmap';
//import Multipie from './Multipie';

//import DemoSankeyLink from './DemoSankeyLink';
//import DemoSankeyNode from './DemoSankeyNode';

//import DemoSankey from './DemoSankey';

class Pie extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };

    }

    render() {

        const data = this.props.agg.buckets.map( item => {
            return { 'name': item['key'], 'value': item['doc_count'], 'url': item['url'] }
        });

        const diameter = this.props.agg['chartwidth'] || 120;

        return (

            <div className={ this.props.agg.aggclass || 'row' }>

                <div className={ this.props.agg.chartclass || 'col-12 col-md-6' }>

                    <PieChart width={ diameter } height={ diameter }>
                    <RCPie
                    dataKey="value"
                    isAnimationActive={ true }
                    animationDuration={ 2000 }
                    data={ data }
                    cx={ diameter * 0.4 }
                    cy={ diameter * 0.4 }
                    outerRadius={ diameter * 0.4 }	
                    >
                    { data.map((entry, index) => 
                            <Cell
                            key={ index }
                            fill={ this.props.selectColor(entry.name, entry.listlabel, index) }
                            onClick={ (e) => {
                            if (entry.url) {
                                //this.redirect(entry.url)
                                const url = process.env.REACT_APP_UI_BASEURL + '/' + entry.url;
                                window.location.href = url;
                            } else {
                                this.props.addagg(
                                    this.props.agg.aggfield || 'filter_aggs',
                                    [
                                    { 'filter_set_key': this.props.agg.fskey, 'filter_key': entry.name, 'filter_label': this.props.agg.fstitle + ' : ' + entry.name },
                                    ]
                                    );
                                }
                            }}
                            cursor={'pointer'}
                            />
                            )}
                    </RCPie>
                    <Tooltip />
                    </PieChart>

                </div>
                <div className={ this.props.agg.keyclass || 'col-12 col-md-6' }>

                    <Key
                    agg={ this.props.agg }
                    addagg={ this.props.addagg }
                    aggactive={this.props.aggactive}
                    selectColor={ this.props.selectColor }
                    />

                </div>

            </div>

        )

    }
}

export default Pie;
