import React, {Component} from 'react';

import Loading from './Loading';
import Error from './Error';

class About extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
            errorLoading: false,
			html: ''
		}
	}

	componentDidMount() {

		const url = process.env.REACT_APP_API_URL + '/about/' + this.props.slug;
		console.log(url);
		fetch(url
		, { credentials: "include" })
		.then(response => {
            if (!response.ok) {
                this.setState({ isLoading: false, errorLoading: response.status });
        	} else {
                return response.json();
            }
        })
		.then(data => {
			this.setState({ html: data.html, isLoading: false });
		})
        .catch(error => {
            this.setState({ isLoading: false, errorLoading: error.toString() });
        });
	}

	render() {

		const { isLoading } = this.state;
        const { errorLoading } = this.state;

		return (

			<React.Fragment>

				{isLoading && <Loading /> }

                { errorLoading && <Error code={ errorLoading } /> }

				{!isLoading && (

					<React.Fragment>

						<span dangerouslySetInnerHTML={{__html: this.state.html}} />
					
					</React.Fragment>

				)}
	
			</React.Fragment>
		)

	}
}

export default About;
