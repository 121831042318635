import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layer } from 'recharts';

export default class Demo extends Component {

    static displayName = 'SankeyLinkDemo';

    static propTypes = {
         sourceX: PropTypes.number,
         targetX: PropTypes.number,
         sourceY: PropTypes.number,
         targetY: PropTypes.number,
         sourceControlX: PropTypes.number,
         targetControlX: PropTypes.number,
         sourceRelativeY: PropTypes.number,
         targetRelativeY: PropTypes.number,
         linkWidth: PropTypes.number,
         index: PropTypes.number,
    }

    state = {
        fill: 'url(#linkGradient)',
    }

    render() {

        const { sourceX, targetX,
            sourceY, targetY,
            sourceControlX, targetControlX,
            //sourceRelativeY, targetRelativeY,
            linkWidth,
            index,
        } = this.props;

        const { fill } = this.state;

        return (

                <Layer key={`CustomLink${index}`}>
                <path
                d={`
                M${sourceX},${sourceY + linkWidth / 2}
                C${sourceControlX},${sourceY + linkWidth / 2}
                ${targetControlX},${targetY + linkWidth / 2}
                ${targetX},${targetY + linkWidth / 2}
                L${targetX},${targetY - linkWidth / 2}
                C${targetControlX},${targetY - linkWidth / 2}
                ${sourceControlX},${sourceY - linkWidth / 2}
                ${sourceX},${sourceY - linkWidth / 2}
                Z
                `}
                fill={fill}
                strokeWidth="0"
                onMouseEnter={() => { this.setState({ fill: 'rgba(0, 136, 254, 0.5)' }); }}
                onMouseLeave={() => { this.setState({ fill: 'url(#linkGradient)' }); }}
                cursor="pointer"
                onClick={
                    () => {
                            console.log(this.props.payload);

                            const filters = this.props.payload.filters.map((filter, idx) => { 

                                return { 'filter_set_key': filter.set, 'filter_key': filter.key, 'filter_label': filter.set + ': ' + filter.key };

                            });

                            console.log(filters);

                            this.props.addagg(this.props.agg.aggfield || 'filter_aggs', filters);

                          }
                    }
                />
                </Layer>
               );
    }
}
