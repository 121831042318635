import React, {Component} from 'react';

import ReactModal from 'react-modal';
import Layout from './Layout';

class Modal extends Component {

	constructor(props) {
		super(props);
		this.state = {
            isOpen: false,
		}
	}

	openModal = () => {

		this.setState({ isOpen: true });

	}
 
	closeModal = () => {

		this.setState({ isOpen: false });

	}

	render() {

        const modalStyles = {
			content : {
				left: '50%',
				right: 'auto',
				bottom: 'auto',
				marginRight: '-40%',
				transform: 'translate(-50%, 0)',
				padding: '0',
				maxWidth: '1000px',
			},
			overlay : {
				position: 'fixed',
				top: '0',
				left: '0',
				right: '0',
				bottom: '0',
				backgroundColor: 'rgba(33, 37, 41, 0.2)',
				overflowY: 'auto',
				transition: 'opacity 300ms ease-in-out',
			}
		};

		return (

            <React.Fragment>

                <span className="cursor-pointer" onClick={this.openModal}>

                    <span dangerouslySetInnerHTML={ { __html: this.props.layout.link } } />

                </span>

                <ReactModal
                isOpen={this.state.isOpen}
                onRequestClose={this.closeModal}
                style={modalStyles}
                contentLabel="Explanation"
                closeTimeoutMS={150}
                ariaHideApp={false}
                >

                <div className="modal-content">

                    { this.props.layout.title !== '' && (

                        <div className="modal-header">

                            <h5 className="modal-title">

                                { this.props.layout.title }

                            </h5>

                            <button type="button" className="close" aria-label="Close" onClick={this.closeModal}>

                                <span aria-hidden="true">&times;</span>

                            </button>

                        </div>

                    )}

                    <div className="modal-body">

                        <Layout
						layout={this.props.layout}
						results={this.props.results}
						addagg={this.props.addagg}
						from={this.props.from}
						switchpage={this.props.switchpage}
						pagesize={this.props.pagesize}
                        pagenav={this.props.pagenav}
                        pagenav_prev={this.props.pagenav_prev}
                        pagenav_next={this.props.pagenav_next}
                        form={this.props.form}
						/>

                    </div>

                <div className="modal-footer">

                    <button type="button" className="btn btn-primary" onClick={this.closeModal}>Close</button>

                </div>

                </div>	

                </ReactModal>

            </React.Fragment>

		)

    }
}

export default Modal;
