import React, {Component} from 'react';

import Agg from './Agg';
import Hitlist from './Hitlist';
import View from './View';
import Map from './Map';
import Post from './Post';
import Modal from './Modal';
import Forcegraph from './Forcegraph';

class Layout extends Component {

	constructor(props) {
		super(props);
		this.state = {
		};

	}
	
	render() {

		let childlist = '';

		if (this.props.layout.children) {

			childlist = this.props.layout.children.map((child, index) => { 
		
				return (

					<React.Fragment key={index}>

						{child.element === 'div' && (

							<Layout
							layout={child}
							results={this.props.results}
							addagg={this.props.addagg}
                            aggactive={this.props.aggactive}
							from={this.props.from}
							switchpage={this.props.switchpage}
                            expandpage={this.props.expandpage}
							pagesize={this.props.pagesize}
                            infinite={this.props.infinite}
                            isLoadingExpand={this.props.isLoadingExpand}
                            pagenav={this.props.pagenav}
                            pagenav_prev={this.props.pagenav_prev}
                            pagenav_next={this.props.pagenav_next}
                            form={this.props.form}
							/>

						)}

						{child.element === 'agg' && this.props.results.aggs[child.aggkey] && (

							<Agg
							aggkey={child.aggkey}
							agg={this.props.results.aggs[child.aggkey]}
							addagg={this.props.addagg}
                            aggactive={this.props.aggactive}
							/>


						)}

						{child.element === 'hitlist' && (

							<Hitlist
							results={this.props.results}
							from={this.props.from}
							switchpage={this.props.switchpage}
                            expandpage={this.props.expandpage}
							pagesize={this.props.pagesize}
                            infinite={this.props.infinite}
                            isLoadingExpand={this.props.isLoadingExpand}
                            pagenav={this.props.pagenav}
                            pagenav_prev={this.props.pagenav_prev}
                            pagenav_next={this.props.pagenav_next}
							/>

						)}

						{child.element === 'view' && (

							<View
							key={child.metaurl + window.location.search}
							metaurl={child.metaurl + window.location.search}
							readurlparams={false}
							/>

						)}

						{child.element === 'map' && (

							<Map
							results={this.props.results}
							/>

						)}

                        {child.element === 'post' && (

							<Post
							url={ child.url }
                            slug={ child.slug }
                            notitle={ child.notitle }
                            modalfold={ child.modalfold }
                            modalbutton={ child.modalbutton }
                            modaltitle={ child.modaltitle }
							/>

						)}

                        {child.element === 'form' && (

                            <React.Fragment>
                                {this.props.form}
							</React.Fragment>

						)}

                        {child.element === 'modal' && (

                            <Modal
							layout={child}
							results={this.props.results}
							addagg={this.props.addagg}
                            aggactive={this.props.aggactive}
							from={this.props.from}
							switchpage={this.props.switchpage}
                            expandpage={this.props.expandpage}
							pagesize={this.props.pagesize}
                            infinite={this.props.infinite}
                            isLoadingExpand={this.props.isLoadingExpand}
                            pagenav={this.props.pagenav}
                            pagenav_prev={this.props.pagenav_prev}
                            pagenav_next={this.props.pagenav_next}
                            form={this.props.form}
                            />

						)}

                        {child.element === 'forcegraph' && (

                            <Forcegraph
                            layout={child}
                            />

						)}

					</React.Fragment>

				);

			});

		}

		return (

			<div className={this.props.layout.className}>

				{this.props.layout.body && (

					<div dangerouslySetInnerHTML={{__html: this.props.layout.body}} />

				)}

				{childlist}
	
			</div>

		)

	}
}

export default Layout;
