import React, {Component} from 'react';

import Loading from './Loading';
import Error from './Error';

//import ReactDOMServer from 'react-dom/server'
import Modal from 'react-modal';

class Post extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
            errorLoading: false,
            title: '',
            prefold: '',
			content: '',
            isOpen: false,
		}
	}

	openModal = () => {

		this.setState({ isOpen: true });

	}
 
	closeModal = () => {

		this.setState({ isOpen: false });

	}

	componentDidMount() {

        let url = null;

        if (this.props.url) {

            //console.log('POST URL WAS SPECIFIC');
            url = this.props.url;

        } else {

            //console.log('POST URL WAS DERIVED FROM A SLUG');
            url = process.env.REACT_APP_WORDPRESS_URL + 'wp-json/wp/v2/pages?slug=' + this.props.slug;
            
        }

        //console.log('POST URL WAS ' + url);

        if (url != null) {

            fetch(url
            , { credentials: "include" })
            .then(response => {
                if (!response.ok) {
                    this.setState({ isLoadingView: false, errorLoading: response.status });
                } else {
                    return response.json();
                }
            })
            .then(data => {

                // Use the browser's own DOM to manipulate the HTML

                let el = document.createElement('div');
                el.innerHTML = data[0]['content']['rendered'];

                // We need to replace internal links in the content
     
                const as = el.getElementsByTagName('a');

                for(let i = 0;i < as.length; i++) {

                    const a = as[i];

                    if (a.href.startsWith(process.env.REACT_APP_WORDPRESS_URL)) {

                        if (a.href.startsWith(process.env.REACT_APP_WORDPRESS_URL + 'view/')) {

                            a.href = process.env.REACT_APP_UI_BASEURL + '/' + a.href.substring(process.env.REACT_APP_WORDPRESS_URL.length);
     
                        } else {

                            a.href = process.env.REACT_APP_UI_BASEURL + '/about/' + a.href.substring(process.env.REACT_APP_WORDPRESS_URL.length);

                        }

                    } else if (a.href.startsWith('https://www.dhi.ac.uk/btm-reactui/')) { // Detect and correct links to the development location

                        a.href = process.env.REACT_APP_UI_BASEURL + '/' + a.href.substring(34);

                    } else if (a.href.startsWith('https://www.beyondthemultiplex.net/')) { // Detect and correct links to old blog location

                        if (a.href.startsWith('https://www.beyondthemultiplex.net/view/')) {

                            a.href = process.env.REACT_APP_UI_BASEURL + '/' + a.href.substring('https://www.beyondthemultiplex.net/'.length);
     
                        } else {

                            a.href = process.env.REACT_APP_UI_BASEURL + '/about/' + a.href.substring('https://www.beyondthemultiplex.net/'.length);

                        }

                    }

                }

                // We want to add better spacing to Wordpress paragraphs
                
                const ps = el.getElementsByTagName('p');

                for(let i = 0;i < ps.length; i++) {

                    const p = ps[i];

                    //p.classList.add("mt-5");
                    p.style.lineHeight = '2em';

                }

                // We want to add better spacing and decoration to Wordpress lists
                
                const lis = el.getElementsByTagName('li');

                for(let i = 0;i < lis.length; i++) {

                    const li = lis[i];

                    li.classList.add("mb-3");
                    li.style.lineHeight = '2em';
                    li.style.listStyleType = 'square';

                }

                // Write our manipulated HTML to our JS string variable.

                let html = el.innerHTML;
                let html_pre = '';



                // modal folds
                
                if (this.props.modalfold) {

                    let mf_pre = document.createElement('div');
                    let mf_post = document.createElement('div');

                    const mfps = el.getElementsByTagName('p');

                    for(let i = 0; i < mfps.length; i++) {

                        const p = mfps[i];
         
                        const clone = p.cloneNode(true);

                        if (i < this.props.modalfold) {

                            if (i === this.props.modalfold - 1) {

                                clone.style.display = 'inline';

                            }

                            mf_pre.append(clone);

                        } else {

                            mf_post.appendChild(clone);

                        }

                    }

                    html_pre = mf_pre.innerHTML;
                    //html = mf_post.innerHTML;

                }

                /*
                if (this.props.fold) {

                    // if a fold has been requested, we must add it to the content
                  
                    const prefold = html.substring(0, this.props.fold);
                    const postfold = html.substring(this.props.fold);

                    const foldid = 'postfold1';

                    const bootstrapfold = (

                        <React.Fragment>

                            <span dangerouslySetInnerHTML={ {__html: prefold } } />

                            <a className="ml-2" data-toggle="collapse" href={'#' + foldid} role="button">
                                    Read more ...
                            </a>

                            <div className="collapse" id={ foldid }>
                                <span dangerouslySetInnerHTML={ {__html: postfold } } />
                            </div>

                        </React.Fragment>

                    );

                    html = ReactDOMServer.renderToStaticMarkup(bootstrapfold);

                }
                */

                this.setState({
                    title: data[0]['title']['rendered'],
                    prefold: html_pre,
                    content: html,
                    isLoading: false,
                });
            })
            .catch(error => {
                this.setState({ isLoading: false, errorLoading: error.toString() });
            });

        }
	}

	render() {

		const { isLoading } = this.state;
        const { errorLoading } = this.state;

        const modalStyles = {
			content : {
				left: '50%',
				right: 'auto',
				bottom: 'auto',
				marginRight: '-40%',
				transform: 'translate(-50%, 0)',
				padding: '0',
				maxWidth: '500px',
			},
			overlay : {
				position: 'fixed',
				top: '0',
				left: '0',
				right: '0',
				bottom: '0',
				backgroundColor: 'rgba(33, 37, 41, 0.2)',
				overflowY: 'auto',
				transition: 'opacity 300ms ease-in-out',
			}
		};

        const inner = (

            <React.Fragment>

                {isLoading && <Loading /> }

                { errorLoading && <Error code={ errorLoading } /> }

                {!isLoading && (

                    <React.Fragment>

                        { !this.props.notitle && (

                            <h1 dangerouslySetInnerHTML={{__html: this.state.title}} />
                        )}

                        <span
                        style={ { height: '2em' } }
                        dangerouslySetInnerHTML={{__html: this.state.content}}
                        />
                    
                    </React.Fragment>

                )}

            </React.Fragment>

        );

		return (

            <React.Fragment>

            { this.props.modalbutton && (

            <React.Fragment>
 
                <Modal
                isOpen={this.state.isOpen}
                onRequestClose={this.closeModal}
                style={modalStyles}
                contentLabel="Explanation"
                closeTimeoutMS={150}
                ariaHideApp={false}
                >

	            <div className="modal-content">

				    <div className="modal-header">

						<h5 className="modal-title">

                            { this.props.modaltitle && (

                                    <span dangerouslySetInnerHTML={{__html: this.props.modaltitle}} />

                            )}

                        </h5>

						<button type="button" className="close" aria-label="Close" onClick={this.closeModal}>

							<span aria-hidden="true">&times;</span>

						</button>

					</div>

					<div className="modal-body">

                        { inner }

                    </div>

            	<div className="modal-footer">

				    <button type="button" className="btn btn-primary" onClick={this.closeModal}>Close</button>

				</div>

				</div>	

                </Modal>

                {/* <span dangerouslySetInnerHTML={ { __html: this.props.premodal } } /> */}

                <span dangerouslySetInnerHTML={ { __html: this.state.prefold } } />

                <span onClick={this.openModal}>
                    <span dangerouslySetInnerHTML={ { __html: this.props.modalbutton } } />
                </span>
	
			</React.Fragment>

            )}

            { !this.props.modalbutton && (

                <React.Fragment>

                    { inner }

                </React.Fragment>

            )}

            </React.Fragment>

		)

	}
}

export default Post;
