import React, {Component} from 'react';
import Select from 'react-select'
import AsyncSelect from 'react-select/async';

class Field extends Component {

	constructor(props) {

		super(props);

        this.timeout =  0;

		this.handleChangeText = this.handleChangeText.bind(this);
		this.handleChangeMultiselect = this.handleChangeMultiselect.bind(this);
		this.handleChangeSelect = this.handleChangeSelect.bind(this);

    }

	handleChangeText(event) {
		this.props.onChange(this.props.id, event.target.value);
	}

	handleChangeMultiselect(data) {
		if (data != null) {
			this.props.onChange(this.props.id, data.map(option => option.value ).join('|'));
		} else {
			this.props.onChange(this.props.id, '');
		}
	}

	handleChangeSelect(data) {
		if (data != null) {
			this.props.onChange(this.props.id, data.value);
		} else {
			this.props.onChange(this.props.id, '');
		}
	}

	loadOptions = (inputValue, callback) => {

        if (this.timeout) { clearTimeout(this.timeout); }

		this.timeout = setTimeout(() => {
			fetch(process.env.REACT_APP_API_URL +
				'/data/view/' + this.props.asyncviewkey +
				'?lookup=' + inputValue + '*', { credentials: "include" })
				.then(response => response.json())
				.then(data => {
					const options = data.hits.map(hit => { return {
						'value' : hit['url'],
						'label' : hit['label']
					} });			
					callback(options);
				});
		}, 1000);
	};

	render() {

		let defaultvalue = null;	

		if ((this.props.type === 'select' || this.props.type === 'select-async') && this.props.value) {

			let sp = this.props.value;

			this.props.options.forEach(function(option) {

				if (option.value === sp) {

					defaultvalue = option;

				}

			});

		}

		let defaultvalues = [];

		if ((this.props.type === 'select-multi' || this.props.type === 'select-async-multi') && this.props.value) {

			let sp = this.props.value.split('|');

			this.props.options.forEach(function(option) {

				//if (sp.indexOf(option.value) !== -1) {
				if (sp.some(element => element.localeCompare(option.value) === 0)) {

					console.log('pass');

					defaultvalues.push(option);

				} else {

					console.log('fail');

				}

			});

		}

		let aggbuttons = '';

		if (this.props.type === 'agg' && this.props.value != null) {

			const vs = this.props.value.split(' +');

                        aggbuttons = vs.map((s, idx) => { 

				if (s.length > 0) {

					const ss = s.split('|');
					const value = ss[0];
					const label = ss[1];

					return (

						<React.Fragment key={idx}>
	
							<button
							type="button"
							className="btn btn-primary mr-2 mb-2 mt-2"
							onClick={() => this.props.delagg(this.props.id, value, label)}
							>
								{label}
								<span className="badge badge-light ml-2"><i className="fa fa-times"></i></span>
							</button>

						</React.Fragment>

					)
				}

				return null;

			});
		}

        let filter_aggs_buttons = '';

		if (this.props.type === 'filter_aggs' && this.props.value != null) {

			const filter_aggs = this.props.value.split(' +');

            filter_aggs_buttons = filter_aggs.map((filter_agg, idx) => { 

				if (filter_agg.length > 0) {

					const parts = filter_agg.split('|');
					const filter_set_key = parts[0];
					const filter_key = parts[1];
                    const filter_label = parts[2];

					return (

						<React.Fragment key={idx}>
	
							<button
							type="button"
							className="btn btn-primary mr-2 mb-2 mt-2"
							onClick={() => this.props.delagg(this.props.id, filter_set_key, filter_key, filter_label)}
							>
								{ filter_label }
								<span className="badge badge-light ml-2"><i className="fa fa-times"></i></span>
							</button>

						</React.Fragment>

					)
				}

				return null;

			});
		}

		const selectStyles = {
			control: (base, state) => ({
				...base,
				//color: "#f00",
				//background: "#333",
				//borderRadius: 0,
				// match with the menu
				//borderRadius: state.isFocused ? "0px 0px 0 0" : 0,
				// Overwrittes the different states of border
				//borderColor: state.isFocused ? "yellow" : "green",
				// Removes weird border around container
				//boxShadow: state.isFocused ? null : null,
				//"&:hover": {
					// Overwrittes the different states of border
				//	borderColor: state.isFocused ? "red" : "blue"
				//}
			}),
			menu: base => ({
				...base,
				//background: "#222",
				// override border radius to match the box
			//	borderRadius: 0,
				// kill the gap
			//	marginTop: 0
			}),
			menuList: base => ({
				...base,
				//background: "#222",
				// kill the white space on first and last option
			//	padding: 0
			}),
			placeholder: (defaultStyles) => {
				return {
					...defaultStyles,
					//color: '#aaa',
				}
			}
		};
	
		return (
			<React.Fragment>
	
			{(this.props.type === 'agg' && this.props.value) && (
		
				<React.Fragment>
	
					{aggbuttons}

				</React.Fragment>
		
			)}

            {(this.props.type === 'filter_aggs' && this.props.value) && (
		
				<React.Fragment>
	
					{filter_aggs_buttons}

				</React.Fragment>
		
			)}

			{this.props.type === 'text' && (
		
				<input
				type="text"
				className="form-control"
				onChange={this.handleChangeText}
				value={this.props.value}
				placeholder={this.props.placeholder}
				id={this.props.id}
				aria-describedby={this.props.id + 'help'}
				/>
		
			)}

			{this.props.type === 'select-multi' && (

				<Select
				styles={selectStyles}
				placeholder={this.props.placeholder}
				isMulti
				name={this.props.id}
				defaultValue={defaultvalues}
				options={this.props.options}
				className="basic-multi-select"
				classNamePrefix="select"
				onChange={this.handleChangeMultiselect}
				aria-describedby={this.props.id + 'help'}
				/>

			)}

			{this.props.type === 'select' && (

				<Select
				styles={selectStyles}
				placeholder={this.props.placeholder}
				name={this.props.id}
				options={this.props.options}
				defaultValue={defaultvalue}
				className="basic-single"
				classNamePrefix="select"
				onChange={this.handleChangeSelect}
				aria-describedby={this.props.id + 'help'}
				/>	

			)}

			{(this.props.type === 'select-async' /*&& !isLoading*/) && (

				<AsyncSelect
				styles={selectStyles}
				cacheOptions
				loadOptions={this.loadOptions}
				defaultOptions={this.props.options}
				defaultValue={defaultvalue}
				onInputChange={this.handleInputChange}
				onChange={this.handleChangeSelect}
				/>	

			)}

			{(this.props.type === 'select-async-multi' /*&& !isLoading*/) && (

				<AsyncSelect
				styles={selectStyles}
				cacheOptions
				isMulti
				loadOptions={this.loadOptions}
				defaultOptions={this.props.options}
				defaultValue={defaultvalues}
				onInputChange={this.handleInputChange}
				onChange={this.handleChangeMultiselect}
				placeholder={this.props.placeholder}
				/>

			)}

			</React.Fragment>
		)

	}
}

export default Field;
