import React, {Component} from 'react';

import { Sankey as RCSankey, Tooltip } from 'recharts';

import SankeyLink from './SankeyLink';
import SankeyNode from './SankeyNode';

class Sankey extends Component {

	constructor(props) {
		super(props);
		this.state = {
		};

	}
	
	render() {

            let sankeydata = {};
	
			sankeydata['nodes'] = this.props.agg.sankey_nodes;

			const buckets = this.props.agg.buckets;

			this.props.agg.sankey_links.forEach(function (node, index) {
				//console.log(buckets[index]);
				node.value = buckets[index]['doc_count'];
                //node.bucket = buckets[index];
				//delete node.filters;
			});
 
			sankeydata['links'] = this.props.agg.sankey_links;
 
		return (

			<div className={ this.props.agg.chartclass || '' }>

                <RCSankey
                width={ this.props.agg.chartwidth || 960 } height={ this.props.agg.chartheight || 500 }
                data={ sankeydata }
                link={ <SankeyLink agg={ this.props.agg } addagg={ this.props.addagg } /> }
                node={ <SankeyNode containerWidth={ this.props.agg.chartwidth || 960 } />}
                /*
                margin={{ top: 20, bottom: 20 }}    
                nodeWidth={ 10 } nodePadding={ 60 }
                linkCurvature={ 0.61 }
                iterations={ 64 }
                isAnimationActive={ true }
                animationDuration={ 4000 }
                link={ <SankeyLink agg={ this.props.agg } addagg={ this.props.addagg } /> }
                node={ <SankeyNode containerWidth={ this.props.agg.chartwidth || 960 } />}
                */
                >
                <defs>
                    <linearGradient id={'linkGradient'}>
                        <stop offset="0%" stopColor="rgba(0, 136, 254, 0.5)" />
                        <stop offset="100%" stopColor="rgba(0, 197, 159, 0.3)" />
                    </linearGradient>
                </defs>
                <Tooltip />
                </RCSankey>

			</div>

		)

	}
}

export default Sankey;
