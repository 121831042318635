import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './custom.scss';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.

import GA4React from 'ga-4-react';

const ga4react = new GA4React(process.env.REACT_APP_GTAG);

const querystring = require('query-string');

function composeTitle() {

    var titleparts = [];

    const qs = querystring.parse(window.location.search);

    if (qs['idkey']) {

        titleparts.push(qs['idkey']);

    }
   
    if (window.location.pathname.startsWith('/view/')) {

        const viewname = window.location.pathname.substring(6);
        titleparts.push(viewname.charAt(0).toUpperCase() + viewname.slice(1));

    }

    if (window.location.pathname.startsWith('/about/')) {

        const viewname = window.location.pathname.substring(7);
        titleparts.push(viewname.charAt(0).toUpperCase() + viewname.slice(1));

    }

    titleparts.push(process.env.REACT_APP_TITLE);

    var title = titleparts.join(' | ');

    title = title.replaceAll(/[^A-Za-z0-9|]/g, ' ');
    
    return title;

}

document.title = composeTitle();

(async () => {

    try {

        await ga4react.initialize();
        console.log('GA initialised');

    } catch {

        console.log('GA unable to load');

    }

    ReactDOM.render(<App />,document.getElementById('root'));

})();

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
