import React, {Component} from 'react';

//import Hit from './Hit';

import { Map as LeafletMap, CircleMarker, Popup, TileLayer, LayersControl } from 'react-leaflet'

//import L from 'leaflet'

import HeatmapLayer from 'react-leaflet-heatmap-layer'

import MarkerClusterGroup from "react-leaflet-markercluster";

import 'leaflet/dist/leaflet.css';

import 'react-leaflet-markercluster/dist/styles.min.css';

class Map extends Component {

	constructor(props) {

		super(props);

		this.state = {
			ico: [],
			awards: [],
            //poorheat: [],
            //public_transport: [],
            //transport_or_parking_cost: [],
            //range_of_films_available: [],
            //range_of_cinemas_available: [],
            //screening_times: [],
            //ticket_cost: [],
            //food_and_drink_cost: [],
            //travel_time: [],
            //limited_free_time: [],
            //having_people_to_go_with: [],
            //childcare_commitments: [],
		};

	}

	componentDidMount() {

		let ico_url = process.env.PUBLIC_URL + '/data/ico.tsv';

		fetch(ico_url)
			.then(response => response.text())
			.then(text => { 

				let ico = [];

				const x = text.split('\n');

				for (let i = 0; i < x.length; i++) {


					if (i > 0) {

						const y = x[i].split('\t');

						if (y[1]) {

							ico.push({ name: y[0], lat: y[1], lng: y[2] });

						}

					}

				}

				this.setState({ ico: ico });

		});



		let awards_url = process.env.PUBLIC_URL + '/data/awards.tsv';

		fetch(awards_url)
			.then(response => response.text())
			.then(text => { 
	
				let awards = [];

				const x = text.split('\n');

				for (let i = 0; i < x.length; i++) {

					if (i > 0) {

						const y = x[i].split('\t');

						if (y[1]) {

                            awards.push({ lat: y[2], lon: y[3], i: (y[1] / 1000) }); 

						}

					}

				}

				this.setState({ awards: awards });

		});

        /*
        let respondents_url = process.env.PUBLIC_URL + '/data/respondents.csv';

		fetch(respondents_url)
			.then(response => response.text())
			.then(text => { 
	
				//let awards = [];
                let poorheat = [];
                let public_transport = [];
                let transport_or_parking_cost = [];
                let range_of_films_available = [];
                let range_of_cinemas_available = [];
                let screening_times = [];
                let ticket_cost = [];
                let food_and_drink_cost = [];
                let travel_time = [];
                let limited_free_time = [];
                let having_people_to_go_with = [];
                let childcare_commitments = [];

				const x = text.split('\n');

				for (let i = 0; i < x.length; i++) {

					if (i > 0) {

						const y = x[i].split(',');

						//if (y[1]) {

                        //    awards.push({ lat: y[2], lon: y[3], i: (y[1] / 1000) }); 

						//}
                        
                        //console.log('Y:');
                        //console.log(y);
                        
                        if (y[2] === 'Very poor') { poorheat.push({ lat: y[0], lon: y[1], i: 100 }); }
                        if (y[2] === 'Poor') { poorheat.push({ lat: y[0], lon: y[1], i: 75 }); }
                        //if (y[2] === 'Good') { poorheat.push({ lat: y[0], lon: y[1], i: -20 }); }
                        //if (y[2] === 'Very good') { poorheat.push({ lat: y[0], lon: y[1], i: -20 }); }

                        if (y[3] === '5') { public_transport.push({ lat: y[0], lon: y[1], i: 100 }); }
                        if (y[3] === '4') { public_transport.push({ lat: y[0], lon: y[1], i: 75 }); }
                        //if (y[3] === '2') { public_transport.push({ lat: y[0], lon: y[1], i: -50 }); }
                        //if (y[3] === '1') { public_transport.push({ lat: y[0], lon: y[1], i: -60 }); }

                        if (y[4] === '5') { transport_or_parking_cost.push({ lat: y[0], lon: y[1], i: 100 }); }
                        if (y[4] === '4') { transport_or_parking_cost.push({ lat: y[0], lon: y[1], i: 75 }); }
                        //if (y[4] === '2') { transport_or_parking_cost.push({ lat: y[0], lon: y[1], i: -50 }); }
                        //if (y[4] === '1') { transport_or_parking_cost.push({ lat: y[0], lon: y[1], i: -60 }); }

                        if (y[5] === '5') { range_of_films_available.push({ lat: y[0], lon: y[1], i: 100 }); }
                        if (y[5] === '4') { range_of_films_available.push({ lat: y[0], lon: y[1], i: 75 }); }
                        //if (y[5] === '2') { range_of_films_available.push({ lat: y[0], lon: y[1], i: -50 }); }
                        //if (y[5] === '1') { range_of_films_available.push({ lat: y[0], lon: y[1], i: -60 }); }

                        if (y[6] === '5') { range_of_cinemas_available.push({ lat: y[0], lon: y[1], i: 100 }); }
                        if (y[6] === '4') { range_of_cinemas_available.push({ lat: y[0], lon: y[1], i: 75 }); }
                        //if (y[6] === '2') { range_of_cinemas_available.push({ lat: y[0], lon: y[1], i: -50 }); }
                        //if (y[6] === '1') { range_of_cinemas_available.push({ lat: y[0], lon: y[1], i: -60 }); }

                        if (y[7] === '5') { screening_times.push({ lat: y[0], lon: y[1], i: 100 }); }
                        if (y[7] === '4') { screening_times.push({ lat: y[0], lon: y[1], i: 75 }); }
                        //if (y[7] === '2') { screening_times.push({ lat: y[0], lon: y[1], i: -50 }); }
                        //if (y[7] === '1') { screening_times.push({ lat: y[0], lon: y[1], i: -60 }); }

                        if (y[8] === '5') { ticket_cost.push({ lat: y[0], lon: y[1], i: 100 }); }
                        if (y[8] === '4') { ticket_cost.push({ lat: y[0], lon: y[1], i: 75 }); }
                        //if (y[8] === '2') { ticket_cost.push({ lat: y[0], lon: y[1], i: -50 }); }
                        //if (y[8] === '1') { ticket_cost.push({ lat: y[0], lon: y[1], i: -60 }); }

                        if (y[9] === '5') { food_and_drink_cost.push({ lat: y[0], lon: y[1], i: 100 }); }
                        if (y[9] === '4') { food_and_drink_cost.push({ lat: y[0], lon: y[1], i: 75 }); }
                        //if (y[9] === '2') { food_and_drink_cost.push({ lat: y[0], lon: y[1], i: -50 }); }
                        //if (y[9] === '1') { food_and_drink_cost.push({ lat: y[0], lon: y[1], i: -60 }); }

                        if (y[10] === '5') { travel_time.push({ lat: y[0], lon: y[1], i: 100 }); }
                        if (y[10] === '4') { travel_time.push({ lat: y[0], lon: y[1], i: 75 }); }
                        //if (y[10] === '2') { travel_time.push({ lat: y[0], lon: y[1], i: -50 }); }
                        //if (y[10] === '1') { travel_time.push({ lat: y[0], lon: y[1], i: -60 }); }

                        if (y[11] === '5') { limited_free_time.push({ lat: y[0], lon: y[1], i: 100 }); }
                        if (y[11] === '4') { limited_free_time.push({ lat: y[0], lon: y[1], i: 75 }); }
                        //if (y[11] === '2') { limited_free_time.push({ lat: y[0], lon: y[1], i: -50 }); }
                        //if (y[11] === '1') { limited_free_time.push({ lat: y[0], lon: y[1], i: -60 }); }

                        if (y[12] === '5') { having_people_to_go_with.push({ lat: y[0], lon: y[1], i: 100 }); }
                        if (y[12] === '4') { having_people_to_go_with.push({ lat: y[0], lon: y[1], i: 75 }); }
                        //if (y[12] === '2') { having_people_to_go_with.push({ lat: y[0], lon: y[1], i: -50 }); }
                        //if (y[12] === '1') { having_people_to_go_with.push({ lat: y[0], lon: y[1], i: -60 }); }

                        if (y[13] === '5') { childcare_commitments.push({ lat: y[0], lon: y[1], i: 100 }); }
                        if (y[13] === '4') { childcare_commitments.push({ lat: y[0], lon: y[1], i: 75 }); }
                        //if (y[13] === '2') { childcare_commitments.push({ lat: y[0], lon: y[1], i: -50 }); }
                        //if (y[13] === '1') { childcare_commitments.push({ lat: y[0], lon: y[1], i: -60 }); }


					}

				}

				this.setState({ 
                    poorheat: poorheat,
                    public_transport: public_transport,
                    transport_or_parking_cost: transport_or_parking_cost,
                    range_of_films_available: range_of_films_available,
                    range_of_cinemas_available: range_of_cinemas_available,
                    screening_times: screening_times,
                    ticket_cost: ticket_cost,
                    food_and_drink_cost: food_and_drink_cost,
                    travel_time: travel_time,
                    limited_free_time: limited_free_time,
                    having_people_to_go_with: having_people_to_go_with,
                    childcare_commitments: childcare_commitments,
                });

		});
        */

		
	}

	render() {

		//console.log('ico:');
		//console.log(this.state.ico);

		const { Overlay } = LayersControl

		/*
		let icon = L.icon({
			iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
			iconUrl: require('leaflet/dist/images/marker-icon.png'),
			shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
			iconSize: [24,36],
			iconAnchor: [12,36],
		})
		*/

		/*
		let awardslist = '';
	
		if (this.state.awards.length > 0) {

			awardslist = this.state.awards.map((award, index) => {

				//const latlng = hit.label.split(' ');

				return (
					<CircleMarker
					center={[award.lat, award.lng]}	
					key={index}
					radius={3}
					fillColor={'#ff00ff'}
					stroke={false}
					fillOpacity={1}
					>
						<Popup>
							{award.name}
						</Popup>
					</CircleMarker>
				);
			});
		}
		*/


		let icolist = '';
	
		if (this.state.ico.length > 0) {

			icolist = this.state.ico.map((ico, index) => {

				//const latlng = hit.label.split(' ');

				return (
					<CircleMarker
					center={[ico.lat, ico.lng]}	
					key={index}
					radius={3}
					fillColor={'#3388ff'}
					stroke={false}
					fillOpacity={1}
					>
						<Popup>
							{ico.name}
						</Popup>
					</CircleMarker>
				);
			});
		}


		/*
		let prolist = '';
	
		if (this.props.results.hits != null) {

			prolist = this.props.results.hits.map((hit, index) => {

				const sp = hit.label.split(' ');

				let color = 'grey';

				if (sp[2] == 'very_poor') { color = 'red'; }
				if (sp[2] == 'poor') { color = 'red'; }
				if (sp[2] == 'average') { color = '#ff7f0e'; }
				if (sp[2] == 'good') { color = '#00cc00'; }
				if (sp[2] == 'very_good') { color = '#00cc00'; }



				return (
					<CircleMarker
					center={[sp[0], sp[1]]}	
					key={index}
					radius={3}
					fillColor={color}
					stroke={false}
					fillOpacity={1}
					/>
				);
			});
		}
		*/

		/*
		let circlemarkerlist = '';

		
		if (this.props.results.hits != null) {

			circlemarkerlist = this.props.results.hits.map((hit, index) => {

				const sp = hit.label.split(' ');

				let color = 'grey';

				console.log(sp[2]);

				if (sp[2] == 'very_poor') { color = '#bb0000'; }
				if (sp[2] == 'poor') { color = '#ff7f0e'; }
				if (sp[2] == 'average') { color = '#ffff00'; }
				if (sp[2] == 'good') { color = '#00bb00'; }
				if (sp[2] == 'very_good') { color = '#00dd00'; }

				return (
					<Circle
					center={[sp[0], sp[1]]}
					fillColor={color}
					stroke={false}
					fillOpacity={1}
					radius={4000}
					key={hit.body}
					/>
				);
			});
		}
		*/
		
		//let poor = [];
		//let average = [];
		//let good = [];

        /*
        let poorheat = [];
        let public_transport = [];
        let transport_or_parking_cost = [];
        let range_of_films_available = [];
        let range_of_cinemas_available = [];
        let screening_times = [];
        let ticket_cost = [];
        let food_and_drink_cost = [];
        let travel_time = [];
        let limited_free_time = [];
        let having_people_to_go_with = [];
        let childcare_commitments = [];

		if (this.props.results.hits != null) {

			//this.props.results.hits.forEach(hit => {

			//	const sp = hit.label.split(' ');

			//	if (sp[2] === 'poor') { poor.push({ lat: sp[0], lon: sp[1], i: 10 }); }
			//	if (sp[2] === 'very_poor') { poor.push({ lat: sp[0], lon: sp[1], i: 20 }); }

			//	if (sp[2] === 'good') { good.push({ lat: sp[0], lon: sp[1], i: 10 }); }
			//	if (sp[2] === 'very_good') { good.push({ lat: sp[0], lon: sp[1], i: 20 }); }

			//	if (sp[2] === 'average') { average.push({ lat: sp[0], lon: sp[1], i: 20 }); }

			//});

				


            this.props.results.hits.forEach(hit => {

				const sp = hit.body.split(',');

                if (sp[2] === 'Very poor') { poorheat.push({ lat: sp[0], lon: sp[1], i: 100 }); }
				if (sp[2] === 'Poor') { poorheat.push({ lat: sp[0], lon: sp[1], i: 75 }); }
				if (sp[2] === 'Good') { poorheat.push({ lat: sp[0], lon: sp[1], i: -20 }); }
				if (sp[2] === 'Very good') { poorheat.push({ lat: sp[0], lon: sp[1], i: -20 }); }

                if (sp[3] === '5') { public_transport.push({ lat: sp[0], lon: sp[1], i: 100 }); }
				if (sp[3] === '4') { public_transport.push({ lat: sp[0], lon: sp[1], i: 75 }); }
				if (sp[3] === '2') { public_transport.push({ lat: sp[0], lon: sp[1], i: -50 }); }
				if (sp[3] === '1') { public_transport.push({ lat: sp[0], lon: sp[1], i: -60 }); }

                if (sp[4] === '5') { transport_or_parking_cost.push({ lat: sp[0], lon: sp[1], i: 100 }); }
				if (sp[4] === '4') { transport_or_parking_cost.push({ lat: sp[0], lon: sp[1], i: 75 }); }
				if (sp[4] === '2') { transport_or_parking_cost.push({ lat: sp[0], lon: sp[1], i: -50 }); }
				if (sp[4] === '1') { transport_or_parking_cost.push({ lat: sp[0], lon: sp[1], i: -60 }); }

                if (sp[5] === '5') { range_of_films_available.push({ lat: sp[0], lon: sp[1], i: 100 }); }
				if (sp[5] === '4') { range_of_films_available.push({ lat: sp[0], lon: sp[1], i: 75 }); }
				if (sp[5] === '2') { range_of_films_available.push({ lat: sp[0], lon: sp[1], i: -50 }); }
				if (sp[5] === '1') { range_of_films_available.push({ lat: sp[0], lon: sp[1], i: -60 }); }

                if (sp[6] === '5') { range_of_cinemas_available.push({ lat: sp[0], lon: sp[1], i: 100 }); }
				if (sp[6] === '4') { range_of_cinemas_available.push({ lat: sp[0], lon: sp[1], i: 75 }); }
				if (sp[6] === '2') { range_of_cinemas_available.push({ lat: sp[0], lon: sp[1], i: -50 }); }
				if (sp[6] === '1') { range_of_cinemas_available.push({ lat: sp[0], lon: sp[1], i: -60 }); }

                if (sp[7] === '5') { screening_times.push({ lat: sp[0], lon: sp[1], i: 100 }); }
				if (sp[7] === '4') { screening_times.push({ lat: sp[0], lon: sp[1], i: 75 }); }
				if (sp[7] === '2') { screening_times.push({ lat: sp[0], lon: sp[1], i: -50 }); }
				if (sp[7] === '1') { screening_times.push({ lat: sp[0], lon: sp[1], i: -60 }); }

                if (sp[8] === '5') { ticket_cost.push({ lat: sp[0], lon: sp[1], i: 100 }); }
				if (sp[8] === '4') { ticket_cost.push({ lat: sp[0], lon: sp[1], i: 75 }); }
				if (sp[8] === '2') { ticket_cost.push({ lat: sp[0], lon: sp[1], i: -50 }); }
				if (sp[8] === '1') { ticket_cost.push({ lat: sp[0], lon: sp[1], i: -60 }); }

                if (sp[9] === '5') { food_and_drink_cost.push({ lat: sp[0], lon: sp[1], i: 100 }); }
				if (sp[9] === '4') { food_and_drink_cost.push({ lat: sp[0], lon: sp[1], i: 75 }); }
				if (sp[9] === '2') { food_and_drink_cost.push({ lat: sp[0], lon: sp[1], i: -50 }); }
				if (sp[9] === '1') { food_and_drink_cost.push({ lat: sp[0], lon: sp[1], i: -60 }); }

                if (sp[10] === '5') { travel_time.push({ lat: sp[0], lon: sp[1], i: 100 }); }
				if (sp[10] === '4') { travel_time.push({ lat: sp[0], lon: sp[1], i: 75 }); }
				if (sp[10] === '2') { travel_time.push({ lat: sp[0], lon: sp[1], i: -50 }); }
				if (sp[10] === '1') { travel_time.push({ lat: sp[0], lon: sp[1], i: -60 }); }

                if (sp[11] === '5') { limited_free_time.push({ lat: sp[0], lon: sp[1], i: 100 }); }
				if (sp[11] === '4') { limited_free_time.push({ lat: sp[0], lon: sp[1], i: 75 }); }
				if (sp[11] === '2') { limited_free_time.push({ lat: sp[0], lon: sp[1], i: -50 }); }
				if (sp[11] === '1') { limited_free_time.push({ lat: sp[0], lon: sp[1], i: -60 }); }

                if (sp[12] === '5') { having_people_to_go_with.push({ lat: sp[0], lon: sp[1], i: 100 }); }
				if (sp[12] === '4') { having_people_to_go_with.push({ lat: sp[0], lon: sp[1], i: 75 }); }
				if (sp[12] === '2') { having_people_to_go_with.push({ lat: sp[0], lon: sp[1], i: -50 }); }
				if (sp[12] === '1') { having_people_to_go_with.push({ lat: sp[0], lon: sp[1], i: -60 }); }

                if (sp[13] === '5') { childcare_commitments.push({ lat: sp[0], lon: sp[1], i: 100 }); }
				if (sp[13] === '4') { childcare_commitments.push({ lat: sp[0], lon: sp[1], i: 75 }); }
				if (sp[13] === '2') { childcare_commitments.push({ lat: sp[0], lon: sp[1], i: -50 }); }
				if (sp[13] === '1') { childcare_commitments.push({ lat: sp[0], lon: sp[1], i: -60 }); }

			});
            */


			/*
			circlemarkerlist = this.props.results.hits.map((hit, index) => {

				const sp = hit.label.split(' ');

				let color = 'grey';

				console.log(sp[2]);

				if (sp[2] == 'very_poor') { color = '#bb0000'; }
				if (sp[2] == 'poor') { color = '#ff7f0e'; }
				if (sp[2] == 'average') { color = '#ffff00'; }
				if (sp[2] == 'good') { color = '#00bb00'; }
				if (sp[2] == 'very_good') { color = '#00dd00'; }

				return (
					<Circle
					center={[sp[0], sp[1]]}
					fillColor={color}
					stroke={false}
					fillOpacity={1}
					radius={4000}
					key={hit.body}
					/>
				);
			});
			

		}
        */

        const awards = this.state.awards;

        //const poorheat = this.state.poorheat;
        //const public_transport = this.state.public_transport;
        //const transport_or_parking_cost = this.state.transport_or_parking_cost;
        //const range_of_films_available = this.state.range_of_films_available;
        //const range_of_cinemas_available = this.state.range_of_cinemas_available;
        //const screening_times = this.state.screening_times;
        //const ticket_cost = this.state.ticket_cost;
        //const food_and_drink_cost = this.state.food_and_drink_cost;
        //const travel_time = this.state.travel_time;
        //const limited_free_time = this.state.limited_free_time;
        //const having_people_to_go_with = this.state.having_people_to_go_with;
        //const childcare_commitments = this.state.childcare_commitments;

        //console.log('Poorheat:');
        //console.log(poorheat);

		return (

		<LeafletMap center={[53, -3]} zoom={6}>

			<LayersControl position="topright">

				<Overlay checked name="OpenStreetMap">

					<TileLayer
					opacity={0.5}
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
					/>

				</Overlay>

				{ icolist.length > 0 && (
	
					<Overlay minZoom={10} maxZoom={10} checked name="ICO Cinema List">

						<MarkerClusterGroup maxClusterRadius={0}>

							{icolist}	

						</MarkerClusterGroup>

					</Overlay>

				)}

                <Overlay checked name="Funding Awards">

					<HeatmapLayer
					points={awards}
					longitudeExtractor={ m => m.lon }
					latitudeExtractor={ m => m.lat }
					intensityExtractor={ m => m.i }
                    maxOpacity={0.1}
                    gradient={ {
                        0.4: 'blue',
                        //0.6: 'aqua',
                        0.7: 'cyan',
                        //0.8: 'lime',
                        1.0: 'aqua'
                    } }
                    />

				</Overlay>

			</LayersControl>

		</LeafletMap>

		);

	}

}

export default Map;
