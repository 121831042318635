import React, {Component} from 'react';
import { Switch, Route, Link, BrowserRouter } from 'react-router-dom';

import View from './View'
import About from './About'
import Post from './Post'

import "bootstrap/js/src/collapse.js";

class Base extends Component {	

	constructor(props) {
		super(props);
		this.state = {
			projects: null,
		}
	}

	render() {

		return (
			<React.Fragment>
				<BrowserRouter basename={process.env.REACT_APP_UI_BASEURL}>
					<nav className="navbar navbar-dark bg-btmblue">
						<div className="container">

                            <Switch>
                                <Route exact path="/" render={ props => ( <div></div> ) } />
                                <Route render={ props => ( 

                                    <Link to="/" style={ {textDecoration : 'none'} }>
                                        <div className="d-sm-none navbar-logo-text-small">Beyond the Multiplex</div>
                                        <div className="d-none d-sm-inline navbar-logo-text">Beyond the Multiplex</div>
                                    </Link>

                                ) } />

                            </Switch>

						    <a className="d-none d-xl-inline text-white ml-auto mr-5" href={ process.env.REACT_APP_UI_BASEURL + '/about/findings'}><strong>Research Findings</strong></a>
    						<a className="d-none d-xl-inline text-white mr-5" href={ process.env.REACT_APP_UI_BASEURL + '/view/search'}><strong>Search</strong></a>
                            <button className="d-none d-xl-inline btn btn-link text-white mr-5 cursor-pointer" data-toggle="collapse" data-target="#dd-read"><strong>Browse</strong></button>
        					<a className="d-none d-xl-inline text-white mr-5" href={ process.env.REACT_APP_UI_BASEURL + '/about/visualise-the-data'}><strong>Visualisations</strong></a>
                            <a className="d-none d-xl-inline text-white mr-5" href={ process.env.REACT_APP_UI_BASEURL + '/view/ontology'}><strong>Ontology</strong></a>

                            {/*
                            <a className="text-white mr-5" href={ process.env.REACT_APP_UI_BASEURL + '/view/ontology'}><strong>Explore the Ontology</strong></a>
                            */}
							
							<button
							className="btn"
							type="button"
							data-toggle="collapse"
							data-target="#sidebar"
							aria-expanded="false"
							aria-controls="sidebar"
							>

                                <span className="d-xl-none mr-2">Navigation</span>
								<span className="d-none d-xl-inline mr-2">More</span>

                                <i className="fa fa-bars text-white"></i>
							</button>						
						</div>
					</nav>

                    <div className="position-absolute text-white collapse pb-2 pt-2 pl-3 pr-4 bg-btmblue" id="dd-read" style={ { zIndex: "99", right: "0", minWidth: '300px' } }>
                                                
                        <a className="text-white mr-5" href={ process.env.REACT_APP_UI_BASEURL + '/view/interviews'}>Audience Interviews</a>
			            <a className="text-white mr-5" href={ process.env.REACT_APP_UI_BASEURL + '/view/experts'}>Expert Interviews</a>
			            <a className="text-white mr-5" href={ process.env.REACT_APP_UI_BASEURL + '/view/elicitations'}>Film Elicitation Groups</a>

					</div>

					<div className="position-absolute text-white collapse pb-2 pt-2 pl-3 pr-4 bg-btmblue" id="sidebar" style={ { zIndex: "99", right: "0", minWidth: '300px' } }>
			            <About
					    key='sidebar'
						slug='sidebar'
						/>
					</div>

                    <Route exact path="/" render={props => (
                       /* <img src={ require('../images/btmheader.jpg').default } className="img-fluid" alt="" /> */
				<div className="btmheader-wrapper">
					<div className="btmheader">
						  <div className="btmheader-text1">Beyond the Multiplex</div>
						  <div className="btmheader-text2">Film Audiences <div className="pipe">|</div> Data Platform</div>
					</div>
				</div>
                    )} />
					<div className="container pb-5">
						<Switch>	
							<Route path="/view/:projectkey/:viewkey" render={ (props) =>
								<View
								key={props.match.params.projectkey + '-' + props.match.params.doctypekey + '-' + props.match.params.viewkey}
								metaurl={
									'/data/meta/' + props.match.params.projectkey + '/' + props.match.params.viewkey
								}
								readurlparams={true}
								/>
							} />
							<Route path="/view/:viewkey" render={ (props) =>
								<View
								key={props.match.params.projectkey + '-' + props.match.params.doctypekey + '-' + props.match.params.viewkey}
								metaurl={
									'/data/meta/' + props.match.params.viewkey
								}
								readurlparams={true}
								/>
							} />
							<Route path="/about/:slug" render={ (props) =>
								<div className="mt-5">
                                <Post
                                slug={ props.match.params.slug }
                                />
                                {/*
                                <Post
                                url={ process.env.REACT_APP_WORDPRESS_URL + 'wp-json/wp/v2/pages?slug=' + props.match.params.slug }
                                />
                                */}
                                {/*
								<About
								key={props.match.params.slug}
								slug={props.match.params.slug}
								/>
                                */}
								</div>
							} />
							<Route path="/" render={ (props) => 
								<View
								key={ 'home' }
								metaurl={
									'/data/meta/home'
								}
								readurlparams={true}
								/>
							} />
						</Switch>

                    </div>

                    <footer className="pt-5 pb-5 bg-btmblue text-white">

						<div className="container">

                            <div className="row">

                                <div className="col-md-6">
				             
				                    <p><i>Beyond the Multiplex: Audiences for Specialised Films in English Regions</i> was a research project funded by the <a href="http://ahrc.ukri.org">Arts & Humanities Research Council (AHRC)</a>. The project was led by the <a href="http://www.glasgow.ac.uk">University of Glasgow</a>, supported by the <a href="http://www.sheffield.ac.uk">University of Sheffield</a>, <a href="http://www.york.ac.uk">University of York</a> and <a href="http://www.liverpool.ac.uk">University of Liverpool</a>.</p>
<p>&copy; Copyright of The University of Glasgow, 2021. All rights reserved.</p>
			                    </div>
				<div className="col-md-3"> 
					<ul>
					<li><a href={ process.env.REACT_APP_UI_BASEURL + '/about/about-the-project'}>About the Project</a></li>
					<li><a href={ process.env.REACT_APP_UI_BASEURL + '/about/research-context'}>Research Context</a></li>
					<li><a href={ process.env.REACT_APP_UI_BASEURL + '/about/research-process'}>Research Process</a></li>
					<li><a href={ process.env.REACT_APP_UI_BASEURL + '/about/about-the-data'}>About the Data</a></li>
            				<li><a href={ process.env.REACT_APP_UI_BASEURL + '/about/uk-film-audience-policy-2010-2018'}>UK Film Audience Policy</a></li>
            				<li><a href={ process.env.REACT_APP_UI_BASEURL + '/about/regional-profiles'}>Regional Profiles</a></li>
            				<li><a href={ process.env.REACT_APP_UI_BASEURL + '/about/download-data'}>Download the Data</a></li>
            				<li><a href={ process.env.REACT_APP_UI_BASEURL + '/about/outputs'}>Research Outputs</a></li>
					<li><a href={ process.env.REACT_APP_UI_BASEURL + '/about/citation'}>Citation</a></li>
					<li><a href={ process.env.REACT_APP_UI_BASEURL + '/about/contact-us'}>Contact Us</a></li>
					
					</ul>
					</div>
			        <div className="col-md-3"> 
						    		<a href="http://ahrc.ukri.org"><img className="dhi-logo" alt="Arts & Humanities Research Council logo" src="https://www.dhi.ac.uk/san/logos/ahrc-logo-white-on-transparent.png"/></a>
						            <a href="https://www.dhi.ac.uk"><img className="dhi-logo" alt="Digital Humanities Institute logo" src="https://www.dhi.ac.uk/san/logos/dhi-logo.png"/></a>
				                </div>

			                </div>

                        </div>

                    </footer>

				</BrowserRouter>

			</React.Fragment>
		)

	}
}

export default Base;
