import React, {Component} from 'react';
import ReactTooltip from 'react-tooltip';

class Multiheatmap extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        function hmcol(value){

            if (isNaN(value) || value === 0.0) { return 'rgb(0,0,0)'; }

            var h = (1.0 - value) * 240
            return 'hsl(' + h + ', 100%, 50%)';

        }

        // before we draw anything let's preprocess this

        const agg = this.props.agg;
        const buckets = this.props.agg.buckets;

        let rowkeys = [];
        let colkeys = [];
        let lookup = {};
        let max = 0;
        let rowmax = [];
        let colmax = [];

        buckets.forEach(function (bucket, index) {

            lookup[bucket['key']] = bucket; 

            const sp = bucket['key'].split('&');
            const rowkey = sp[0];
            const colkey = sp[1];

            if (bucket['doc_count'] > max) { max = bucket['doc_count']; }
            if (bucket['doc_count'] > rowmax[rowkey] || !rowmax[rowkey]) { rowmax[rowkey] = bucket['doc_count']; }
            if (bucket['doc_count'] > colmax[colkey] || !colmax[colkey]) { colmax[colkey] = bucket['doc_count']; }

            if (rowkeys.includes(rowkey) === false) { rowkeys.push(rowkey); }
            if (colkeys.includes(colkey) === false) { colkeys.push(colkey); }

        });

        console.log(rowmax);
        console.log(colmax);

        const rows = rowkeys.map((rowkey, index) => { 

            const cols = colkeys.map((colkey, index) => { 

                const bucket = lookup[rowkey + '&' + colkey];
                const count = bucket['doc_count'];

                const col = hmcol(count / max);
                //const col = hmcol(count / rowmax[rowkey]);
                //const col = hmcol(count / colmax[colkey]);
                //const col = hmcol(count / 5271);

                return (

                    <td
                    key={ index }
                    data-tip={ agg.rowtitle + ": " + rowkey + " | " + agg.coltitle + ": " + colkey + " = " + count } 
                    style={{ 
                        backgroundColor: col,
                        height: agg.chartheight || '15px',
                        width: agg.chartheight || '15px', 
                        fontSize: '10pt', 
                        cursor: 'pointer', 
                        transition: 'background-color 4s ease'
                    }}
                    onClick={
                            () => {
                                    this.props.addagg(
                                        agg.aggfield || 'filter_aggs',
                                        [
                                            { 'filter_set_key': agg.rowfskey, 'filter_key': rowkey, 'filter_label': agg.rowtitle + ': ' + rowkey },
                                            { 'filter_set_key': agg.colfskey, 'filter_key': colkey, 'filter_label': agg.coltitle + ': ' + colkey },
                                        ]
                                    );

                                  }
                        }
                    >

                    {/*this.props.agg.aggfield && (

                            <span
                            className="btn-link text-break"
                            onClick={() => this.props.addagg(this.props.agg.aggfield, bucket['key'], bucket['formlabel'])}
                            >
                                { count }
                            </span>

                        )*/}


                    </td>

                )

            });


            return (

                <tr key={ index }>
                    { cols }

                    { agg.noclabels && index === 0 && (
                        <th rowSpan={ rowkeys.length } style={{ verticalAlign: 'top' }}>
                            <span style={{ writingMode: 'vertical-rl', fontSize: '10pt', marginTop: '2px', whiteSpace: 'nowrap' }}>
                                { agg.rowtitle }
                            </span>
                        </th>
                    ) }
    
                    { !agg.noclabels && (
                        <td style={{ fontSize: '10pt', paddingLeft: '12px', whiteSpace: 'nowrap' }}>
                            { rowkey }
                        </td>
                    ) }

                </tr>

            )

        });

        const cols = colkeys.map((colkey, index) => { 

            return (

                <td key={ index } style={{ verticalAlign: 'top' }}>
                    <span style={{ writingMode: 'vertical-rl', fontSize: '10pt', marginTop: '12px', whiteSpace: 'nowrap' }}>
                        { colkey }
                    </span>
                </td>

            )

        });

        return (

			<div className={ agg.aggclass || 'row' }>

				<div className={ agg.chartclass || 'col-12 col-md-8' }>

					<table>
						<tbody>
							{ !agg.noclabels && (

								<tr>
									<td colSpan={colkeys.length}>
									</td>
									<th style={{ fontSize: '10pt', paddingLeft: '12px', whiteSpace: 'nowrap', color: '#f46f0c' }}>
										{ agg.rowtitle }
									</th>
								</tr>

							) }

							{ rows }

							{ agg.noclabels && (

								<tr>
									<th style={{ fontSize: '10pt' }} colSpan={ colkeys.length } >
										{ agg.coltitle }
									</th>
								</tr>

							) }

							{ !agg.noclabels && (

								<tr>
								{ cols }
								<th style={{ verticalAlign: 'top' }}>
								<span style={{ writingMode: 'vertical-rl', fontSize: '10pt', marginTop: '14px', whiteSpace: 'nowrap', color: '#f46f0c' }}>
									{ agg.coltitle }
								</span>
								</th>
								</tr>

							) }

						</tbody>
					</table>
					<ReactTooltip />
				</div>

				<div className={ agg.keyclass || 'col-12 col-md-4 mt-5' }>

                    <div className="row">

                        <div className="col-1 mt-2">
                            <span style={ {
                                backgroundImage: 'linear-gradient(' + hmcol(1) + ', ' + hmcol(0.90) + ', ' + hmcol(0.80) + ', ' + hmcol(0.70) + ', ' + hmcol(0.60) + ', ' + hmcol(0.50) + ', ' + hmcol(0.40) + ', ' + hmcol(0.30) + ', ' + hmcol(0.20) + ', ' + hmcol(0.10) + ', ' + hmcol(0) + ')',
                                width: '1em',
                                height: '12.5em',
                                display: 'inline-block'
                            } }></span>
                        </div>

                        <div className="col-9">

                            <div className="row">

                                <div className="col-12">
                                    {/*<span className="mr-2" style={ {backgroundColor: hmcol(1), width: '1em', height: '1em', display: 'inline-block'} }></span>*/}
                                    <span className="text-break" style={{ fontSize: '10pt', whiteSpace: 'nowrap' }}><span>- { max }</span></span>
                                </div>
                                {/*
                                <div className="col-12">
                                    <span className="mr-2" style={ {backgroundColor: hmcol(0.90), width: '1em', height: '1em', display: 'inline-block'} }></span>
                                    <span className="text-break"><span>{ Math.floor(max * 0.90) }</span></span>
                                </div>
                                <div className="col-12">
                                    <span className="mr-2" style={ {backgroundColor: hmcol(0.80), width: '1em', height: '1em', display: 'inline-block'} }></span>
                                    <span className="text-break"><span>{ Math.floor(max * 0.80) }</span></span>
                                </div>
                                */}
                                <div className="col-12 mt-4">
                                    {/*<span className="mr-2" style={ {backgroundColor: hmcol(0.70), width: '1em', height: '1em', display: 'inline-block'} }></span>*/}
                                    <span className="text-break" style={{ fontSize: '10pt', whiteSpace: 'nowrap' }}><span>- { Math.floor(max * 0.75) }</span></span>
                                </div>
                                {/*
                                <div className="col-12">
                                    <span className="mr-2" style={ {backgroundColor: hmcol(0.60), width: '1em', height: '1em', display: 'inline-block'} }></span>
                                    <span className="text-break"><span>{ Math.floor(max * 0.60) }</span></span>
                                </div>
                                */}
                                <div className="col-12 mt-4">
                                    {/*<span className="mr-2" style={ {backgroundColor: hmcol(0.50), width: '1em', height: '1em', display: 'inline-block'} }></span>*/}
                                    <span className="text-break" style={{ fontSize: '10pt', whiteSpace: 'nowrap' }}><span>- { Math.floor(max * 0.50) }</span></span>
                                </div>
                                {/*
                                <div className="col-12">
                                    <span className="mr-2" style={ {backgroundColor: hmcol(0.40), width: '1em', height: '1em', display: 'inline-block'} }></span>
                                    <span className="text-break"><span>{ Math.floor(max * 0.40) }</span></span>
                                </div>
                                <div className="col-12">
                                    <span className="mr-2" style={ {backgroundColor: hmcol(0.30), width: '1em', height: '1em', display: 'inline-block'} }></span>
                                    <span className="text-break"><span>{ Math.floor(max * 0.30) }</span></span>
                                </div>
                                */}
                                <div className="col-12 mt-4">
                                    {/*<span className="mr-2" style={ {backgroundColor: hmcol(0.20), width: '1em', height: '1em', display: 'inline-block'} }></span>*/}
                                    <span className="text-break" style={{ fontSize: '10pt', whiteSpace: 'nowrap' }}><span>- { Math.floor(max * 0.25) }</span></span>
                                </div>
                                {/*
                                <div className="col-12">
                                    <span className="mr-2" style={ {backgroundColor: hmcol(0.10), width: '1em', height: '1em', display: 'inline-block'} }></span>
                                    <span className="text-break"><span>{ Math.floor(max * 0.10) }</span></span>
                                </div>
                                */}
                                <div className="col-12 mt-4">
                                    {/*<span className="mr-2" style={ {backgroundColor: hmcol(0), width: '1em', height: '1em', display: 'inline-block'} }></span>*/}
                                    <span className="text-break" style={{ fontSize: '10pt', whiteSpace: 'nowrap' }}><span>- 0</span></span>
                                </div>

                            </div>

                        </div>

                    </div>

				</div>

			</div>

        )

    }
}

export default Multiheatmap;
