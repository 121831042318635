import React, {Component} from 'react';

class Loading extends Component {

	constructor(props) {
		super(props);
		this.state = {
		}
	}

	render() {

		return (
			<div className="d-flex justify-content-center mt-4 pt-4 mb-4 pb-4 display-4">
				<i className="fa fa-ticket fa-spin fast-spin"></i>
			</div>
		)

	}
}

export default Loading;
