import React, {Component} from 'react';

import Field from './Field';
import Results from './Results';
import Layout from './Layout';

class Form extends Component {

	constructor(props) {

		super(props);

		this.state = {
			fieldvalues: null,
			from: 0,
		}

		this.timeout =  0;

		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
        this.handleExpand = this.handleExpand.bind(this);

	}
	
	handleFieldChange(fieldId, value) {

		let fieldvalues = this.state.fieldvalues;
		fieldvalues[fieldId] = value;

		if (this.props.response === 'live') {

			this.setState({ fieldvalues }, this.handleSubmit);

		} else {

			this.setState({ fieldvalues });

		}
	}

	handleSubmit(event) {

		if (event != null) { event.preventDefault(); }

		if (this.timeout) { clearTimeout(this.timeout); }
		this.timeout = setTimeout(() => { this.props.submit(this.state, true) }, 1000);

	}

    handleExpand(event) {

        this.props.expand(this.state, true);

	}

	handleSubmitInit(state) {

		console.log('handleSubmitInit is running');
		console.log(state);
		// we need this separate call so that the init sumbit doesn't get added to history
		this.props.submit(state, false);

	}

	formInit = () => {

		console.log('formInit is running');

		if (this.props.init === 'submit') {

			if (!window.location.search) {

				console.log('formInit is going to do a submit');
				this.handleSubmitInit(this.state);

			} else {

				console.log('formInit will not do a submit because there is a request string');

			}

		} else {
			console.log('formInit will not do a submit because this is not an init: submit view');
		}

	}

	setFieldValues = () => {

		console.log('setFieldValues is running');

		let fieldvalues = {};
	
		if (this.props.fields) {
			this.props.fields.forEach(field => {
				if (field.initval) { 
					fieldvalues[field.fieldkey] = field.initval; 
				} else { 
					fieldvalues[field.fieldkey] = ''; 
				} 
			} );
		}

        var initfrom = 0

        if (this.props.initfrom !== 0) {

            initfrom = this.props.initfrom;
        
        }

		this.setState({
			fieldvalues: fieldvalues,
            from: initfrom,
		}, this.formInit);

	}

	switchpage = (from) => {

		console.log('switchpage ran');
		console.log(from);
		this.setState({ from }, this.handleSubmit);

	}

    expandpage = (from) => {

		console.log('expandpage ran');
		console.log(from);
		this.setState({ from }, this.handleExpand);

	}

    //addagg = (fieldid, value, label) => {

        //console.log('addagg ran');
		//console.log(fieldid);
		//console.log(value);
		//console.log(label);

		//let fieldvalues = this.state.fieldvalues;

		//console.log(fieldvalues);

		//fieldvalues[fieldid] = fieldvalues[fieldid] + ' +' + value + '|' + label;

		//console.log(fieldvalues);

		//this.setState({ fieldvalues }, this.handleSubmit);

	//}

    addagg = (fieldkey, filter_aggs) => {

        console.log('addagg ran');
        console.log(fieldkey);
		console.log(filter_aggs);

        let fieldvalues = this.state.fieldvalues;
 
        filter_aggs.forEach(filter_agg => {

            console.log(filter_agg);

            fieldvalues[fieldkey] = fieldvalues[fieldkey] + ' +' + filter_agg.filter_set_key + '|' + filter_agg.filter_key + '|' + filter_agg.filter_label;

        });

        console.log(fieldvalues[fieldkey]);

        this.setState({ fieldvalues: fieldvalues, from: 0 }, this.handleSubmit);

	}

    aggactive = (filter_set_key, filter_key) => {
 
        let filter_aggs = this.state.fieldvalues['filter_aggs'];
 
        const fas = filter_aggs.split(' +');
        
        for (var i = 0; i < fas.length; i++) {

            const fa = fas[i];
 
            if (fa.includes('|')) {

                const segs = fa.split('|'); 

                if (filter_set_key === segs[0] && filter_key === segs[1]) {

                    return true; 

                }

            }

        }
        
        return false;

	}

    delagg = (fieldkey, filter_set_key, filter_key, filter_label) => {

		console.log('delagg ran');
		console.log(fieldkey);
		console.log(filter_set_key);
		console.log(filter_key);
		console.log(filter_label);

		let fieldvalues = this.state.fieldvalues;

		//console.log(fieldvalues);

		fieldvalues[fieldkey] = fieldvalues[fieldkey].replace(' +' + filter_set_key + '|' + filter_key + '|' + filter_label, '');

		//console.log(fieldvalues);

		this.setState({ fieldvalues: fieldvalues, from: 0 }, this.handleSubmit);

	}

	//delagg = (fieldid, value, label) => {

	//	console.log('delagg ran');
	//	console.log(fieldid);
	//	console.log(value);
	//	console.log(label);

	//	let fieldvalues = this.state.fieldvalues;

	//	console.log(fieldvalues);

	//	fieldvalues[fieldid] = fieldvalues[fieldid].replace(' +' + value + '|' + label, '');

	//	console.log(fieldvalues);

	//	this.setState({ fieldvalues }, this.handleSubmit);

	//}

	componentDidMount() {

		this.setFieldValues();

	}

	render() {
	
		let fields = '';

		if (this.props.fields != null && this.state != null && this.state.fieldvalues != null) {

                        fields = this.props.fields.map(field => {

				let labelclass = 'col-12 col-md-2 text-left text-md-right';
				let inputclass = 'col-12 col-md-10';

				if (field.fullwidth) {

					labelclass = 'col-0 text-right';
					inputclass = 'col-12';

				}

				if (field.type !== 'agg') {
					
					inputclass = inputclass + ' mb-2';

				}

				return (

				<React.Fragment key={field.fieldkey}>

					<div key={field.fieldkey} className="form-row">
					
						<div className={labelclass}>

							{ (field.displayname.length > 0) && (

								<label className="font-weight-bold mt-2" htmlFor={field.id} dangerouslySetInnerHTML={{__html: field.displayname}}  />

							) }

						</div>

						<div className={inputclass}>
							<Field
								id={field.fieldkey}
								type={field.type}
								displayname={field.displayname}	
								help={field.help}
								placeholder={field.placeholder}
								options={field.options}	
								value={this.state.fieldvalues[field.fieldkey]}
								onChange={this.handleFieldChange}
								asyncprojectkey={field.asyncprojectkey}
								asyncdoctypekey={field.asyncdoctypekey}
								asyncviewkey={field.asyncviewkey}
								asyncvaluefield={field.asyncvaluefield}
								asynclabelfield={field.asynclabelfield}
								delagg={this.delagg}
							/>

							{ (field.help) && (

								<small id={field.id + 'help'} className="form-text text-muted">{field.help}</small>

							)}
						</div>
					</div>
	
				</React.Fragment>
			)

			});

		}

        const form = (

            <form onSubmit={this.handleSubmit}>

                {fields}

                {this.props.response === 'submit' &&

                    <div className="form-row mt-3">
                        <div className="col-12 text-right">
                            <button type="submit" className="btn btn-primary">
                                Search
                            </button>
                        </div>
                    </div>

                }

            </form>

        );


        const results = (

            <Results
                results={this.props.results}
                switchpage={this.switchpage}
                expandpage={this.expandpage}
                from={this.state.from}
                pagesize={this.props.pagesize}
                infinite={this.props.infinite}
                pagenav={this.props.pagenav}
                pagenav_prev={this.props.pagenav_prev}
                pagenav_next={this.props.pagenav_next}
                addagg={this.addagg}
                aggactive={this.aggactive}
                isLoadingResults={this.props.isLoadingResults}
                isLoadingExpand={this.props.isLoadingExpand}
                errorLoadingResults={this.props.errorLoadingResults}
            />

        );

		return (

			<React.Fragment>

                {this.props.body && (

                    <div dangerouslySetInnerHTML={ { __html: this.props.body } } />

                )}

                {this.props.bodylayout && (

                    <Layout
                        layout={this.props.bodylayout}
                        form={form}
                    />

                )}

                {!this.props.bodylayout && (

                    <React.Fragment>

                        { form }
            
                    </React.Fragment>


                )}

                { results }

			</React.Fragment>
		)

	}
}

export default Form;
