import React, {Component} from 'react';

import Form from './Form';
import Loading from './Loading';
import Error from './Error';

import { withRouter } from "react-router-dom";

const querystring = require('query-string');

class View extends Component {

	constructor(props) {
		super(props);
		this.state = {
			fields: null,
			results: null,
			projectdisplayname: '',
			doctypedisplayname: '',
			viewdisplayname: '',
			body: '',
            bodylayout: null,
			init: null,
			response: null,
			get: null,
			pagesize: null,
            infinite: null,
            pagenav: null,
            pagenav_prev: null,
            pagenav_next: null, 
			isLoadingView: false,
			isLoadingResults: false,
            isLoadingExpand: false,
            errorLoadingView: false,
            errorLoadingResults: false,
            initfrom: 0,
		}
	}

	qssubmit = () => {

		console.log('qssubmit');

		if (window.location.search) {

			if (this.state.get) {

				console.log('**** QSSUBMIT WILL BE PERFORMED ****');

				const url = process.env.REACT_APP_API_URL + this.state.get + window.location.search

				console.log(url);

				this.setState({ isLoadingResults: true });

				fetch(url, { credentials: "include" })
					.then(response => {
                        if (!response.ok) {
                            this.setState({ isLoadingResults: false, errorLoadingResults: response.status });
                        } else {
                            return response.json();
                        }
                    })
				    .then(data => {
                        if (data) {
                            this.setState({ results: data, isLoadingResults: false })
                        }
                    })
                    .catch(error => {
                        this.setState({ isLoading: false, errorLoading: error.toString() });
                    });

			} else {

				console.log('qssubmit did not do a submit because the meta did not send a get');

			}

		} else {

			console.log('qssubmit did not do a submit because there is no request string');

		}

	}

	submit = (state, history) => {
	
		let fieldvalues = state.fieldvalues;

		fieldvalues['from'] = state['from'];

        // When using infinite, we need to reset the from when doing a new search
        delete fieldvalues.from;

		const qs = querystring.stringify(fieldvalues);

		// don't create an entry in history if this was an init: submit
		if (history) {
			console.log('submit added to history');
			this.props.history.push(this.props.location.pathname + '?' + qs);
		} else {
			console.log('submit did not add to history');
		}

		console.log('**** SUBMIT WILL BE PERFORMED ****');
		console.log(state);

		const url = process.env.REACT_APP_API_URL + this.state.get + '?' + qs;

		console.log(url);

		this.setState({ isLoadingResults: true });

		fetch(url, { credentials: "include" })
			.then(response => {
                if (!response.ok) {
                    this.setState({ isLoadingResults: false, errorLoadingResults: response.status });
                } else {
                    return response.json();
                }
            })
			.then(data => { 
                if (data) {
                    this.setState({ results: data, isLoadingResults: false });
                }
            })
            .catch(error => {
                this.setState({ isLoadingResults: false, errorLoading: error.toString() });
            });

	}

    expand = (state, history) => {

        this.setState({ isLoadingExpand: true });

		let fieldvalues = state.fieldvalues;

		fieldvalues['from'] = state['from'];

		const qs = querystring.stringify(fieldvalues);

		// don't create an entry in history because this is an expand
		//if (history) {
		//	console.log('submit added to history');
		//	this.props.history.push(this.props.location.pathname + '?' + qs);
		//} else {
		//	console.log('submit did not add to history');
		//}

		console.log('**** SUBMIT WILL BE PERFORMED ****');
		console.log(state);

		const url = process.env.REACT_APP_API_URL + this.state.get + '?' + qs;

		console.log(url);
	
		fetch(url, { credentials: "include" })
			.then(response => {
                if (!response.ok) {
                    this.setState({ isLoadingExpand: false, errorLoadingResults: response.status });
                } else {
                    return response.json();
                }
            })
			.then(data => { 

                if (data) {

                    console.log('Got some expanded results');
                    console.log(data);
                    console.log(this.state.results);

                    let new_results = this.state.results;
                    new_results.hits = new_results.hits.concat(data.hits);

                    this.setState({ results: new_results, isLoadingExpand: false });

                }

            })

            .catch(error => {
                this.setState({ isLoading: false, errorLoading: error.toString() });
            });

	}

	configViewKey = (data) => {
						
		const qs = querystring.parse(window.location.search);

		// add all the initvals from the qs
		if (data.fields) {
			data.fields.forEach(function(field) {
				console.log(field);
				if (qs[field.fieldkey]) {
					field.initval = qs[field.fieldkey];
				} else if (field.initval) {
					// don't overwrite an initval we get from the server
				} else {
					field.initval = '';
				}
			});
		}

        var initfrom = 0;

        if (qs['from']) {

            initfrom = parseInt(qs['from']);

        }

		this.setState({
			projectdisplayname: data.projectdisplayname,
			doctypedisplayname: data.doctypedisplayname,
			viewdisplayname: data.viewdisplayname,
			body: data.body,
            bodylayout: data.bodylayout,
			fields: data.fields,
			isLoadingView: false,
			init: data.init,
			response: data.response,
			results: null,
			get: data.get,
			pagesize: data.pagesize,
            infinite: data.infinite,
            pagenav: data.pagenav,
            pagenav_prev: data.pagenav_prev,
            pagenav_next: data.pagenav_next, 
            initfrom: initfrom,
		}, this.qssubmit);

	}
	
	fetchMetadata = () => {

		let url = process.env.REACT_APP_API_URL + this.props.metaurl;

		if (this.props.readurlparams) {
			const qs = querystring.parse(window.location.search);
			url = url + '?' + querystring.stringify(qs);
		}

		console.log(url);

		this.setState({ isLoadingView: true });

		fetch(url
		, { credentials: "include" })
		.then(response => {
            if (!response.ok) {
                this.setState({ isLoadingView: false, errorLoadingView: response.status });
        	} else {
                return response.json();
            }
        })
		.then(data => {
            if (data) {
                this.configViewKey(data);
            }
        })
        .catch(error => {
            this.setState({ isLoadingView: false, errorLoadingView: error.toString() });
        });

	}

	componentDidMount() {

		this.fetchMetadata();
	}
	
	render() {

		const { isLoadingView } = this.state;
        const { errorLoadingView } = this.state;

		return (
			<React.Fragment>

				{ isLoadingView && <Loading /> }

                { errorLoadingView && <Error code={ errorLoadingView } /> }

				{!isLoadingView && (
					<Form
						submit={this.submit}
                        expand={this.expand}
						fields={this.state.fields}
						init={this.state.init}
						response={this.state.response}
						results={this.state.results}
						pagesize={this.state.pagesize}
                        infinite={this.state.infinite}
                        pagenav={this.state.pagenav}
                        pagenav_prev={this.state.pagenav_prev}
                        pagenav_next={this.state.pagenav_next}
           				isLoadingResults={this.state.isLoadingResults}
                        isLoadingExpand={this.state.isLoadingExpand}
                        errorLoadingResults={this.state.errorLoadingResults}
                        body={this.state.body}
                        bodylayout={this.state.bodylayout}
                        initfrom={this.state.initfrom}
					/>
				)}
	
			</React.Fragment>
		)

	}
}

export default withRouter(View);
