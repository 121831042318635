import React, {Component} from 'react';

import ForceGraph2D from 'react-force-graph-2d';

import {useState, useEffect, useRef, useCallback} from 'react';

import Loading from './Loading';

// this is a function component. It's a pain. I think D3 requires it to do a dynamic graph.
// I could be wrong about that.

const DynamicGraph = (init) => {

	const [data, setData] = useState({ nodes: [{ id: 0 }], links: [] });
	//const [data, setData] = useState({ nodes: init.initdata.nodes, links: init.initdata.links });
	const [focusnode, setFocusnode] = useState({ focusnode: null });
	//const [nodeinfo, setNodeinfo] = useState({ nodeinfo: null });
	const [loading, setLoading] = useState({ loading: true });
	const fgRef = useRef();

	function pushNodeIfNew(nodes, candidate_node) {

		let node_already_exists = false;

		nodes.forEach(node => {

			if (node.id === candidate_node.id) { node_already_exists = true; }

		})

		if (node_already_exists === false) {

			nodes.push(candidate_node);

		}
	
	}

	function pushLinkIfNew(links, candidate_link) {

		let link_already_exists = false;

		links.forEach(link => {

			if (link.source.id === candidate_link.source && link.target.id === candidate_link.target) { link_already_exists = true; }
			//if (link.source.id === candidate_link.target && link.target.id === candidate_link.source) { link_already_exists = true; }

		})

		if (link_already_exists === false) {

			links.push(candidate_link);

		}

	}

    function chunkString(str, len) {
        let input = str.trim().split(' ');
        let [index, output] = [0, []]
        output[index] = '';
        input.forEach(word => {
            let temp = `${output[index]} ${word}`.trim()
            if (temp.length <= len) {
                output[index] = temp;
            } else {
                index++;
                output[index] = word;
            }
        })
        return output
    }

    /*function btm_legend(idkey) {*/
	const btm_legend = useCallback((idkey) => {

        const parts = idkey.split('$');

        var ret = parts[parts.length - 1];

        ret = ret.replaceAll('_', ' ');

        ret = chunkString(ret.trim(), 15);

        ret = ret.filter(function (el) { // the chunking function sometimes leaves empty lines
            return el !== '';
        });

        return ret;

    /*}*/
	}, []);

    /*function btm_name(idkey) {*/
	const btm_name = useCallback((idkey) => {
       
        var ret = '<small>'

        if (idkey.includes('|')) {

            const parts = idkey.split('|');

            ret = ret + '<div class="text-center">' + btm_name_entity(parts[0]) + '</div>';

            ret = ret + '<div class="text-center"><span class="badge badge-btmblue pl-4 pr-4 pt-2 pb-2 mt-2 mb-2">' + btm_name_entity(parts[1]) + '</span></div>';
            
            ret = ret + '<div class="text-center">' + btm_name_entity(parts[2]) + '</div>';

        } else {

            ret = ret + btm_name_entity(idkey);
       
        }

        ret = ret + '</small>'

        return ret;

    /*}*/
	}, []);

    function btm_name_entity(idkey) {

        var ret = '';

        const parts = idkey.split('$');

        parts.forEach(function (part, idx) {

            part = part.replaceAll('_', ' ');

            if (idx < parts.length - 1) {

                ret = ret + '<span style="color:#bbb">' + part + '</span> <strong>/</strong> ';

            } else {

                ret = ret + '<strong>' + part + '</strong> ';

            }

        });

        return ret;

    }

    function btm_summary(node) {

        return (

            <div className="p-3 border-bottom">
                <h2>
                    <div dangerouslySetInnerHTML={{__html: btm_name(node.focusnode.id)}} />
                </h2>
		<p>This diagram visualises your current location within the ontology. Zoom in and out by using the scroll wheel on your mouse, or by moving two fingers on a track pad. Click and drag to move around the diagram. Click on a node to reveal other parts of the ontology. Click on the link "More about this node" to view the data within this part of the ontology.
		</p>
                <div className="text-right">
                    <a href={process.env.REACT_APP_UI_BASEURL + '/view/entity?idkey=' + encodeURIComponent(node.focusnode.id)}>More about this node <i className="fa fa-external-link"></i></a>
                </div>
            </div>

        )

    }

	const expandNode = useCallback((forcedata, node, data) => {


		console.log(data);

        // ancestry
        
        pushNodeIfNew(forcedata['nodes'], { 'id': 'Ontology', 'name': 'Ontology', 'color': '#f4640c', 'val': 3, 'legend': ['Ontology'] });

        const parts = node.id.split('$');

        let history = '';
        let ancestor = '';

        parts.forEach(function (part, idx) {

            //if (idx < parts.length - 1) {

                history = history + part;

                pushNodeIfNew(forcedata['nodes'], { 'id' : history, 'name' : btm_name(history), 'color' : '#2c313f', 'val': 3, 'legend': btm_legend(history) });

                if (ancestor !== '') {

                    pushLinkIfNew(forcedata['links'], { 'source' : ancestor, 'target' : history, 'color' : '#999' });

                } else {

                    pushLinkIfNew(forcedata['links'], { 'source' : 'Ontology', 'target' : history, 'color' : '#999' });

                }

                ancestor = history;

                history = history + "$";

            //}

        });

        if (data.hits) {

			data.hits.forEach(hit => {

				//forcedata['nodes'].push({ 'id' : source.sourcename, 'name' : 'source ' + source.given, value : 1, 'type' : 'source' });
				//var label = 'Audience Survey "' + source.given + '"';
				//var type = 'survey'
				//if (source.sourcetype === 'audience_interview') {
				//	label = 'Audience Interview "' + source.given + '"';
				//	type = 'interview'
				//}
				console.log(hit.body);

                if (hit.body.includes('|')) {

                    // Relationship

                    const parts = hit.body.split('|');
 
                    pushNodeIfNew(forcedata['nodes'], { 'id' : parts[0], 'name' : btm_name(parts[0]), 'color' : '#2c313f', 'val': 3, 'legend': btm_legend(parts[0])  });
                    pushNodeIfNew(forcedata['nodes'], { 'id' : parts[2], 'name' : btm_name(parts[2]), 'color' : '#2c313f', 'val': 3, 'legend': btm_legend(parts[2])  });


                    // Relationships represented by links:

    				//pushLinkIfNew(forcedata['links'], { 'source' : parts[0], 'target' : parts[2], 'name': hit.body, 'label' : btm_label(hit.body), 'type' : 'relationship' });


                    // Relationships represented by nodes:

                    pushNodeIfNew(forcedata['nodes'], { 'id' : hit.body, 'name' : btm_name(hit.body), 'color' : '#fb0', 'val': 3, 'legend': btm_legend(parts[1]) });

                    pushLinkIfNew(forcedata['links'], { 'source' : parts[0], 'target' : hit.body, 'color' : '#f90' });
                    pushLinkIfNew(forcedata['links'], { 'source' : hit.body, 'target' : parts[2], 'color' : '#f90' });

                } else {

				    pushNodeIfNew(forcedata['nodes'], { 'id' : hit.body, 'name' : btm_name(hit.body), 'color' : '#2c313f', 'val': 3, 'legend': btm_legend(hit.body) });
    				pushLinkIfNew(forcedata['links'], { 'source' : node.id, 'target' : hit.body, 'color' : '#999' });

                }

			});

		}

	//function expandNode(forcedata, node) {

        /*
		if (data.sources) {

			data.sources.forEach(source => {

				//forcedata['nodes'].push({ 'id' : source.sourcename, 'name' : 'source ' + source.given, value : 1, 'type' : 'source' });
				var label = 'Audience Survey "' + source.given + '"';
				var type = 'survey'
				if (source.sourcetype === 'audience_interview') {
					label = 'Audience Interview "' + source.given + '"';
					type = 'interview'
				}
				//console.log(source);
				pushNodeIfNew(forcedata['nodes'], { 'id' : source.sourcename, 'name' : label, value : 1, 'type' : type });
				pushLinkIfNew(forcedata['links'], { 'source' : node.id, 'target' : source.sourcename });

			});

		}

		if (data.texts) {

			data.texts.forEach(text => {

				//var label = '' + text.given + ' "' + text.text.substring(0, 20) + '" ...';
				var label = 'Text: "' + text.given + '"';
				if (text.text) {
					label = '' + text.given + ' "' + text.text.substring(0, 20) + '" ...';
				}
				//console.log("TEXT:");
				//console.log(text);
				pushNodeIfNew(forcedata['nodes'], { 'id' : text.id, 'name' : label, value : 1, 'type' : 'text' });
				pushLinkIfNew(forcedata['links'], { 'source' : node.id, 'target' : text.id });

			});

		}

		if (data.entities) {

			data.entities.forEach(entity => {

				if (entity != null) {

					var label = 'Entity: "' + entity.label + '"';
					pushNodeIfNew(forcedata['nodes'], { 'id' : entity.type + entity.id, 'name' : label, value : 1, 'type' : 'entity' });
					pushLinkIfNew(forcedata['links'], { 'source' : node.id, 'target' : entity.type + entity.id });

				}
	
			});

		}

		if (data.relsfrom) {

			data.relsfrom.forEach(relfrom => {

				var label = 'Entity: "' + relfrom.label + '"';
				pushNodeIfNew(forcedata['nodes'], { 'id' : relfrom.type + relfrom.id, 'name' : label, value : 1, 'type' : 'entity' });
				pushLinkIfNew(forcedata['links'], { 'source' : node.id, 'target' : relfrom.type + relfrom.id });

				if (relfrom.entity2) {

					pushNodeIfNew(forcedata['nodes'], {
						'id' : relfrom.entity2.type + relfrom.entity2.id,
						'name' : 'entity ' + relfrom.entity2.label,
						value : 1,
						'type' : 'entity'
					});

					pushLinkIfNew(forcedata['links'], { 'source' : relfrom.type + relfrom.id, 'target' : relfrom.entity2.type + relfrom.entity2.id });

				}

			});

		}

		if (data.relsto) {

			data.relsto.forEach(relto => {

				var label = 'Entity: "' + relto.label + '"';
				pushNodeIfNew(forcedata['nodes'], { 'id' : relto.type + relto.id, 'name' : label, value : 1, 'type' : 'entity' });
				pushLinkIfNew(forcedata['links'], { 'source' : node.id, 'target' : relto.type + relto.id });

				if (relto.entity1) {

					pushNodeIfNew(forcedata['nodes'], {
						'id' : relto.entity1.type + relto.entity1.id,
						'name' : 'entity ' + relto.entity1.label,
						value : 1,
						'type' : 'entity'
					});

					pushLinkIfNew(forcedata['links'], { 'source' : relto.type + relto.id, 'target' : relto.entity1.type + relto.entity1.id });

				}

			});

		}
        */

		return forcedata;

	}, [btm_legend, btm_name]);

	useEffect(() => {

        fgRef.current.d3Force('charge').strength(-55);

        fgRef.current.zoom(7);
 
        //console.log(" -------- fgRef --------");
        //console.log(fgRef);

		// this runs only once

		setLoading(({ loading }) => {
			return {
				loading: true
			};
		});

		console.log("**************** useEffect ****************");

		let forcedata = {};

		forcedata['nodes'] = [];
		forcedata['links'] = [];

		//console.log("INIT");
		//console.log(init);
        
        let idkey = init.layout.body;

        console.log("INIT IDKEY WAS: " + idkey);

        // We don't support starting a forcegraph from a relationship, so we instead use the first node.
        if (idkey.indexOf('|') !== -1) {

            idkey = idkey.substr(0, idkey.indexOf('|')); 

        }

		//var label = init.nodetype + ': ' + init.nodeid;
		let node = { 'id' : idkey, 'name' : btm_name(idkey), 'color' : '#2c313f', 'val': 3, 'legend': btm_legend(idkey) };

		pushNodeIfNew(forcedata['nodes'], node);

		//var ftype = node.type;
		//if (ftype === 'survey' || ftype === 'interview') { ftype = 'source'; }

		//var url = process.env.REACT_APP_API_URL + '/btm/' + ftype + '/ontology/' + node.id;

        var url = process.env.REACT_APP_API_URL + '/data/view/entity-forcegraph-children?idkey=' + encodeURIComponent(idkey);

        if (idkey === 'Ontology') {

            url = process.env.REACT_APP_API_URL + '/data/view/entity-forcegraph-top';

        }

		console.log(url);

		fetch(url, { credentials: "include" })
			.then(response => response.json())
			.then(data => {
	
                
				forcedata = expandNode(forcedata, node, data);
	
				setData(({ nodes, links }) => {
					//	const id = nodes.length;
					return {
						nodes: forcedata['nodes'],
						links: forcedata['links']
					};
				});

                
				setFocusnode(({ focusnode }) => {
					return {
						focusnode: forcedata['nodes'][0]
					};
 
				});
 
                /*
				setNodeinfo(({ nodeinfo }) => {
					return {
						nodeinfo: data
					};
				});
                */

				setLoading(({ loading }) => {
					return {
						loading: false
					};
				});
 
			});


		
	}, [init.nodetype, init.nodeid, expandNode, init.layout.body, btm_legend, btm_name]);

	const handleClick = useCallback(node => {

		setLoading(({ loading }) => {
			return {
				loading: true
			};
		});

		//const { nodes, links } = data;

		// Remove node on click
		//const newLinks = links.filter(l => l.source !== node && l.target !== node); // Remove links attached to node
		//const newNodes = nodes.slice();
		//newNodes.splice(node.id, 1); // Remove node
		//newNodes.forEach((n, idx) => { n.id = idx; }); // Reset node ids to array index

		//setData({ nodes: newNodes, links: newLinks });

		setFocusnode(({ focusnode }) => {
			return {
				focusnode: node
			};
		});

		//var ftype = node.type;
		//if (ftype === 'survey' || ftype === 'interview') { ftype = 'source'; }

		//var url = process.env.REACT_APP_API_URL + '/btm/' + ftype + '/ontology/' + node.id; 

        const idkey = node.id;

        if (idkey.includes('|') === false) { // We do not allow the expansion of relationship nodes

            var url = process.env.REACT_APP_API_URL + '/data/view/entity-forcegraph-children?idkey=' + encodeURIComponent(idkey);

            if (idkey === 'Ontology') {

                url = process.env.REACT_APP_API_URL + '/data/view/entity-forcegraph-top';

            }

            console.log(url);
        
            fetch(url, { credentials: "include" })
                .then(response => response.json())
                .then(data => {

                    //setNodeinfo(({ nodeinfo }) => {
                    //    return {
                    //        nodeinfo: data
                    //    };
                    //});

                    setLoading(({ loading }) => {
                        return {
                            loading: false
                        };
                    });

                    setData(({ nodes, links }) => {
                        //	const id = nodes.length;
                        
                        let forcedata = { nodes: nodes, links: links };

                        forcedata = expandNode(forcedata, node, data);
               
                        return {
                            nodes: forcedata['nodes'],
                            links: forcedata['links']
                        };
                    });

                    setTimeout(function() {
 
                        fgRef.current.centerAt(node.x, node.y, 100);
                    
                    }, 700);

                    //setData(({ nodes, links }) => {
                    //	const id = nodes.length;
                    //	return {
                    //		nodes: [...nodes, { id }],
                    //		links: [...links, { source: id, target: Math.round(Math.random() * (id-1)) }]
                    //	};
                    //});


                });

        } else {

            setLoading(({ loading }) => {

                return {
                    loading: false
                };

            });

            setTimeout(function() {
 
                fgRef.current.centerAt(node.x, node.y, 100);
                    
            }, 700);

        }


	}, [/*data, setData, focusnode,*/ setFocusnode, expandNode]);

    /*
	const expand = useCallback(node => {

		setData(({ nodes, links }) => {

			let forcedata = { nodes: nodes, links: links };

			forcedata = expandNode(forcedata, focusnode.focusnode, nodeinfo.nodeinfo);

			//nodeinfo.nodeinfo.texts.forEach(text => {

			//	let new_node_id = 'text' + text.id;

			//	pushNodeIfNew(nodes, { 'id' : new_node_id, 'name' : 'text ' + text.given, value : 1, 'type' : 'text' });
	
			//	pushLinkIfNew(links, { 'source' : focusnode.focusnode.id, 'target' : new_node_id });

			//});

			return {
				nodes: forcedata['nodes'],
				links: forcedata['links']
				//nodes: nodes,
				//links: links
			};
	
		});

	}, [setData, focusnode, nodeinfo, expandNode]);
    */

	const nco = (node, ctx) => {

        if (node === focusnode.focusnode) {

			ctx.fillStyle = '#f4640c';
			ctx.beginPath(); ctx.arc(node.x, node.y, 7 + 0.5, 0, 2 * Math.PI, false); ctx.fill();

		}

        ctx.fillStyle = node.color;

        ctx.beginPath(); ctx.arc(node.x, node.y, 7, 0, 2 * Math.PI, false); ctx.fill();

        if (node.legend) {

            ctx.font = '1.5px Sans-Serif'; ctx.textAlign = 'center'; ctx.textBaseline = 'middle';

            var linespace = 2;
            ctx.lineWidth = 0.2;

            if (node.legend.length > 2) {

                ctx.strokeStyle = '#2c313f'; ctx.strokeText(node.legend[0], node.x, node.y - linespace); ctx.fillStyle = '#fff'; ctx.fillText(node.legend[0], node.x, node.y - linespace);
                ctx.strokeStyle = '#2c313f'; ctx.strokeText(node.legend[1], node.x, node.y + 0); ctx.fillStyle = '#fff'; ctx.fillText(node.legend[1], node.x, node.y + 0);
                ctx.strokeStyle = '#2c313f'; ctx.strokeText(node.legend[2], node.x, node.y + linespace); ctx.fillStyle = '#fff'; ctx.fillText(node.legend[2], node.x, node.y + linespace);

            } else if (node.legend.length > 1) {

                ctx.strokeStyle = '#2c313f'; ctx.strokeText(node.legend[0], node.x, node.y - (linespace / 2)); ctx.fillStyle = '#fff'; ctx.fillText(node.legend[0], node.x, node.y - (linespace / 2)); 
                ctx.strokeStyle = '#2c313f'; ctx.strokeText(node.legend[1], node.x, node.y + (linespace / 2)); ctx.fillStyle = '#fff'; ctx.fillText(node.legend[1], node.x, node.y + (linespace / 2)); 

            } else {

                 ctx.strokeStyle = '#2c313f'; ctx.strokeText(node.legend[0], node.x, node.y); ctx.fillStyle = '#fff'; ctx.fillText(node.legend[0], node.x, node.y); 
           
            }

        }

        
        /*
		if (node === focusnode.focusnode) {

			ctx.fillStyle = '#f4640c';
			ctx.beginPath(); ctx.arc(node.x, node.y, 26, 0, 2 * Math.PI, false); ctx.fill();

		}
        

        ctx.fillStyle = '#2c313f';
        */

        /*
		if (node.type === 'entity') {

			if ((typeof node.id === 'string' || node.id instanceof String) && node.id.startsWith('Relationship')) {

				ctx.fillStyle = '#dfa107';

			} else {

				ctx.fillStyle = '#ffc107';

			}

		} else if (node.type === 'interview') {

			ctx.fillStyle = '#28a745';

		} else if (node.type === 'survey') {

			ctx.fillStyle = '#17a2b8';

		} else if (node.type === 'text') {

			ctx.fillStyle = '#777777';

		} else {

			ctx.fillStyle = 'grey';

		}
        */

        /*
		ctx.beginPath(); ctx.arc(node.x, node.y, 24, 0, 2 * Math.PI, false); ctx.fill();

        if (node.label) {

            if (node.label.length > 2) {

                ctx.font = '6px Sans-Serif'; ctx.textAlign = 'center'; ctx.textBaseline = 'middle'; ctx.strokeStyle = '#2c313f'; ctx.lineWidth = 1; ctx.strokeText(node.label[0], node.x, node.y - 8); ctx.fillStyle = '#fff'; ctx.fillText(node.label[0], node.x, node.y - 8);
                ctx.font = '6px Sans-Serif'; ctx.textAlign = 'center'; ctx.textBaseline = 'middle'; ctx.strokeStyle = '#2c313f'; ctx.lineWidth = 1; ctx.strokeText(node.label[1], node.x, node.y + 0); ctx.fillStyle = '#fff'; ctx.fillText(node.label[1], node.x, node.y + 0);
                ctx.font = '6px Sans-Serif'; ctx.textAlign = 'center'; ctx.textBaseline = 'middle'; ctx.strokeStyle = '#2c313f'; ctx.lineWidth = 1; ctx.strokeText(node.label[2], node.x, node.y + 8); ctx.fillStyle = '#fff'; ctx.fillText(node.label[2], node.x, node.y + 8);

            } else if (node.label.length > 1) {

                ctx.font = '6px Sans-Serif'; ctx.textAlign = 'center'; ctx.textBaseline = 'middle'; ctx.strokeStyle = '#2c313f'; ctx.lineWidth = 1; ctx.strokeText(node.label[0], node.x, node.y - 4); ctx.fillStyle = '#fff'; ctx.fillText(node.label[0], node.x, node.y - 4); 
                ctx.font = '6px Sans-Serif'; ctx.textAlign = 'center'; ctx.textBaseline = 'middle'; ctx.strokeStyle = '#2c313f'; ctx.lineWidth = 1; ctx.strokeText(node.label[1], node.x, node.y + 4); ctx.fillStyle = '#fff'; ctx.fillText(node.label[1], node.x, node.y + 4); 

            } else {

                ctx.font = '6px Sans-Serif'; ctx.textAlign = 'center'; ctx.textBaseline = 'middle'; ctx.strokeStyle = '#2c313f'; ctx.lineWidth = 1; ctx.strokeText(node.label[0], node.x, node.y); ctx.fillStyle = '#fff'; ctx.fillText(node.label[0], node.x, node.y); 
           
            }

        }
        */

        //[
		//() => { ctx.fillRect(x - 6, y - 4, 12, 8); }, // rectangle
		//() => { ctx.beginPath(); ctx.moveTo(x, y - 5); ctx.lineTo(x - 5, y + 5); ctx.lineTo(x + 5, y + 5); ctx.fill(); }, // triangle
		//() => { ctx.beginPath(); ctx.arc(x, y, 5, 0, 2 * Math.PI, false); ctx.fill(); }, // circle
		//() => { ctx.font = '10px Sans-Serif'; ctx.textAlign = 'center'; ctx.textBaseline = 'middle'; ctx.fillText('Text', x, y); } // text
		//][id%4]();
	}

    /*
    const lco = (link, ctx) => {

		//if (node === focusnode.focusnode) {

		//	ctx.fillStyle = 'red';
		//	ctx.beginPath(); ctx.arc(node.x, node.y, 5 * 1.4, 0, 2 * Math.PI, false); ctx.fill();

		//}
        
        //console.log(link.type);

        if (link.type === 'relationship') {

            //console.log('Drawing a relationship link');

            ctx.strokeStyle = '#fa0';
            ctx.lineWidth = 2;

            ctx.beginPath();
            ctx.moveTo(link.source.x, link.source.y);
            ctx.lineTo(link.target.x, link.target.y);
            ctx.stroke();

            const midx=link.source.x+(link.target.x-link.source.x)*0.50;
            const midy=link.source.y+(link.target.y-link.source.y)*0.50;

            ctx.font = '5px Sans-Serif';

            ctx.fillStyle = '#233';

            var tw = ctx.measureText(link.label).width;
            ctx.fillRect(midx - (tw / 2) - 2, midy - 5, tw + 4, 9);

            ctx.textAlign = 'center'; ctx.textBaseline = 'middle'; ctx.fillStyle = '#fff'; ctx.fillText(link.label, midx, midy);

        } else {

            ctx.strokeStyle = '#aaa'; 
            ctx.lineWidth = 1;

            ctx.beginPath();
            ctx.moveTo(link.source.x, link.source.y);
            ctx.lineTo(link.target.x, link.target.y);
            ctx.stroke();

        }

        
        
        /*
		if (node.type === 'entity') {

			if ((typeof node.id === 'string' || node.id instanceof String) && node.id.startsWith('Relationship')) {

				ctx.fillStyle = '#dfa107';

			} else {

				ctx.fillStyle = '#ffc107';

			}

		} else if (node.type === 'interview') {

			ctx.fillStyle = '#28a745';

		} else if (node.type === 'survey') {

			ctx.fillStyle = '#17a2b8';

		} else if (node.type === 'text') {

			ctx.fillStyle = '#777777';

		} else {

			ctx.fillStyle = 'grey';

		}
        

		//ctx.beginPath(); ctx.arc(node.x, node.y, 5, 0, 2 * Math.PI, false); ctx.fill();

		//[
		//() => { ctx.fillRect(x - 6, y - 4, 12, 8); }, // rectangle
		//() => { ctx.beginPath(); ctx.moveTo(x, y - 5); ctx.lineTo(x - 5, y + 5); ctx.lineTo(x + 5, y + 5); ctx.fill(); }, // triangle
		//() => { ctx.beginPath(); ctx.arc(x, y, 5, 0, 2 * Math.PI, false); ctx.fill(); }, // circle
		//() => { ctx.font = '10px Sans-Serif'; ctx.textAlign = 'center'; ctx.textBaseline = 'middle'; ctx.fillText('Text', x, y); } // text
		//][id%4]();
	}
    */

	return (
        <React.Fragment>
			<div className="row">

                <div className="col-12">	
				
					{ loading.loading === true && <Loading /> }

					{ loading.loading === false &&

						<React.Fragment>

                        {btm_summary(focusnode)}
                        
						</React.Fragment>
						
					}
				</div>
				<div className="col-12">

                    <ForceGraph2D
                        graphData={data}
                        onNodeClick={handleClick}
                        linkDirectionalArrowLength={2}
                        linkDirectionalArrowRelPos={1}
                        width={950}
						height={500}
                        nodeCanvasObject={nco}
                        ref={fgRef}
					/>

                    {/*
					<ForceGraph2D
						width={1000}
						height={600}
						ref={fgRef}
						enableNodeDrag={true}
						onNodeClick={handleClick}
						graphData={data}
                        nodeRelSize={24}
                        nodeVal={1}
						nodeCanvasObject={nco}
                        linkDirectionalArrowLength={10}
                        linkDirectionalArrowRelPos={1}
                        linkWidth={3}
					/>
                    */}

					{/*loading.loading === true &&
						<button type="button" className="btn btn-dark" onClick={expand}>Please wait ... </button>
					*/}
					{/*loading.loading === false &&
						<button type="button" className="btn btn-primary" onClick={expand}>Expand</button>
					*/}
				</div>	
			</div>
		</React.Fragment>
        );

};

class Forcegraph extends Component {

	constructor(props) {
		super(props);
		this.state = {
		}
	}
	
	render() {

		return (
			<DynamicGraph
				layout={this.props.layout}
			/>
		)

	}
}

export default Forcegraph;

