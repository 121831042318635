import React, {Component} from 'react';

import Loading from './Loading';
import Layout from './Layout';
import Error from './Error';

class Results extends Component {

	constructor(props) {
		super(props);
		this.state = {
		};

	}

	render() {
	
		console.log('results rendering');
		console.log(this.props.results);

		return (
			<React.Fragment>

				{ (this.props.results == null && this.props.isLoadingResults) && <Loading /> }

                { (this.props.errorLoadingResults) && <Error code={ this.props.errorLoadingResults } /> }

				{this.props.results != null && (

					<React.Fragment>

						{this.props.results.total === 0 && (
	
							<React.Fragment>
								{this.props.results.nohitstext && (

									<p dangerouslySetInnerHTML={{__html: this.props.results.nohitstext}} />

								)}
							</React.Fragment>

						)}

						{this.props.results.total !== 0 && (

							<React.Fragment>

								{this.props.results.layout && (

									<Layout
										layout={this.props.results.layout}
										results={this.props.results}
										addagg={this.props.addagg}
                                        aggactive={this.props.aggactive}
										from={this.props.from}
										switchpage={this.props.switchpage}
                                        expandpage={this.props.expandpage}
										pagesize={this.props.pagesize}
                                        infinite={this.props.infinite}
                                        isLoadingExpand={this.props.isLoadingExpand}
                                        pagenav={this.props.pagenav}
                                        pagenav_prev={this.props.pagenav_prev}
                                        pagenav_next={this.props.pagenav_next}
									/>

								)}

							</React.Fragment>

						)}

					</React.Fragment>

				)}
			</React.Fragment>
		)

	}
}

export default Results;
